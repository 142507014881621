import { Login, Profile,Dashboard,Upload, DeviceToken } from "./ActonTypes";

const LoginData = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case Login:
      return [...payload];
    default:
      return state;
  }
};


const ProfileData = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case Profile:
      return {...payload};
    default:
      return state;
  }
}

const DashboardData = (state = "CreateNew", action) => {
  const { type, payload } = action;
  switch (type) {
    case Dashboard:
      return payload;
    default:
      return state;
  }
}
const UploadingData = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case Upload:
      return payload;
    default:
      return state;
  }
}

const DeviceTokenData = (state = "", action) => {
  const { type, payload } = action;
  switch (type) {
    case DeviceToken:
      return payload;
    default:
      return state;
  }
}

export { LoginData, ProfileData,DashboardData,UploadingData, DeviceTokenData };