import React from "react";
import "./home.css";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import LocalStorage from "../../../service/LocalStorage";
import VTSCANS from "../../../assets/image/icons/VTSCANS.png"

const GetStarted = () => {
  const localStorage = new LocalStorage()
  const history = useHistory();
  const token = localStorage.getItem("token");
  return (
    <div className="getStarted">
      <div className="container">
        <h3>Get Started</h3>
        <p className="getStartedAboutUs"><b>About VTS:</b>  Since 2017, VTS has been delivering industry leading reality capture services for Telecom, AEC (Architecture, Engineering and Construction) and Insurance clients. Virtual Technology Simplified enables virtual access to properties and assets by capturing buildings and structures with 3D reality capture technology. Leveraging a combined 100+ years of experience with cutting-edge 3D laser scanning, imaging and LIDAR technology, VTS has built a best-in-class capture operations team. Vertical data capture, digital twin services and capture technology are game changing advantages that streamline processes and lead to better decisions. The new VTS’ 360Capture™ mobile app empowers anyone to capture a property or asset and transform the data into actionable insights.</p>
        <p>Let's create high quality 3D data from photos and videos today!</p>
        <Tooltip title="SIGN UP" arrow>
          <button className="buttonMain" onClick={() => history.push("/signup")}>
            {token ? "Lets create": "SIGN UP"}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default GetStarted;
