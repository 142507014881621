import React, { Suspense, useState, useEffect, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html, useProgress } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { useLoader } from "@react-three/fiber";
import { ToastContainer, toast } from "react-toastify";
import API from "../../service/api_endpoint";
import "./viewer.css";
import axios from "axios";
import VideoCaptureModal from "../Modals/VideoCaptureModal";
import { successToast, errorToast, infoToast, warnToast } from "../Toast/Toast";


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  object loading Progress ~~~~~~~~~~~~~~~~~~~~~~~~~

function Loader() {
  const { progress } = useProgress();

  return (
    <Html style={{ width: "100px", color: "#255FFF" }}>
      {progress.toFixed(1)} %
    </Html>
  );
}
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ OBJ working ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const Model = ({ objFile, mtlFile, setRender }) => {
  const materials = useLoader(MTLLoader, mtlFile);

  const obj = useLoader(OBJLoader, objFile, (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  useEffect(() => {
    setRender(true);
  }, []);
  return obj ? <primitive object={obj} scale={30} /> : <p>Error</p>;
};



var canvas;
var timeout = null;
var videoUrl;
var screenArray = [];
var UploadedSs = 0;

const ObjLoader = ({ item, url, setShareUrl, shareUrl }) => {
  const [objFile, setObjFile] = useState("");
  const [mtlFile, setMtlFile] = useState("");
  const [isVideoStart, setIsVideoStart] = useState(false);
  const [isVideoComplete, setIsVideoComplete] = useState(false);
  var count = 25;
  const [seconds, setSeconds] = useState(25);
  const [previewModal, setPreviewModal] = useState(false);

  const [progress, setProgress] = useState(0);
  const [isUploadStart, setIsUploadStart] = useState(false);
  const ref = useRef(null);
  const [render, setRender] = useState(false);

  const getImage = async () => {
    const blob = await new Promise((resolve) => ref.current.toBlob(resolve));
    screenArray.push(blob);
  };

  useEffect(() => {
    url.map((item, i) => {
      if (item.split(".").pop() === "obj") {
        setObjFile(item);
      }
      if (item.split(".").pop() === "mtl") {
        setMtlFile(item);
      }
    });
    canvas = document.querySelector("canvas");
    const ctx = canvas.getContext("webgl", { preserveDrawingBuffer: true });
  }, []);

  const startRecording = () => {
    setIsVideoStart(true);
    countDown();
  };

  const stopRecording = () => {
    setIsUploadStart(true);
    clearInterval(timeout);
    setIsVideoStart(false);
    setSeconds(25);
    count = 25;
    setPreviewModal(true);
    uploadVideo();
  };

  // ~~~~~~~~~~~~~~~~~  stopping recording after timeover ~~~~~~~~~~~~~

  useEffect(() => {
    if (isUploadStart) {
      setProgress((UploadedSs / screenArray.length) * 100);
    }
  }, [isUploadStart]);

  useEffect(() => {
    if (seconds === 0) {
      stopRecording();
    }
  }, [seconds]);

  const uploadVideo = async () => {
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    };

    const form = new FormData();
    form.append("initiateId", item.initiateId);
    form.append("userId", item.userId);
    form.append("fileExt", "png");
    form.append("ratio", `${canvas.width}x${canvas.height}`);

    screenArray.map((item, i) => {
      const file = new File([item], `${i}.png`, { type: "image/png" });
      form.append("shareFiles", file);
    });

    const data = await axios
      .post(`${API.shareFile}`, form, config)
      .then((res) => {
        UploadedSs++;
        setIsVideoStart(false);
        setIsUploadStart(false);
        setShareUrl(res.data.data);
        videoUrl = res.data.data;
        setIsVideoComplete(true);
        setProgress(0);
      })
      .catch((err) => {
        errorToast("Error Occured, Please try again");
        screenArray = [];
        setIsVideoStart(false);
        setIsUploadStart(false);
        setIsVideoComplete(false);
        setPreviewModal(false);
        setShareUrl("");
        setProgress(0);
      });
  };

  const discardVideo = () => {
    const options = {
      initiateId: item.initiateId,
      userId: item.userId.toString(),
    };

    axios
      .delete(`${API.deleteFile}`, { data: options })
      .then((res) => {
        successToast("Video Removed, Recapture again");
        screenArray = [];
        setIsVideoStart(false);
        setIsUploadStart(false);
        setIsVideoComplete(false);
        setPreviewModal(false);
        setShareUrl("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const countDown = () => {
    timeout = setInterval(() => {
      --count;
      getImage();

      setSeconds(count);
    }, 1000);
  };

  return (
    <>
      <ToastContainer autoClose={1000} draggableDirection="y" />
      <div className="viewerContainer">
        <Canvas
          pixelRatio={window.devicePixelRatio}
          camera={{ position: [40, 0, 40], fov: 50 }}
          ref={ref}
        >
          <ambientLight intensity={0.8} />
          {/* <directionalLight position={[10, 10, 10]} intensity={0.2} /> */}
          <hemisphereLight intensity={0.2} />

          <Suspense fallback={<Loader />}>
            {url !== [] && (
              <Model
                mtlFile={mtlFile}
                objFile={objFile}
                // ref={ref}
                setRender={setRender}
              />
            )}
            <OrbitControls />
          </Suspense>
        </Canvas>

        {!item.shareUrl.length > 0 && !isVideoStart && !shareUrl.length > 0 && (
          <p style={{ marginTop: "30px", fontWeight: "600" }}>
            Capture cool videos of your model and share on social platforms.
          </p>
        )}
        {(item.shareUrl.length > 0 || shareUrl.length > 0) && (
          <p style={{ marginTop: "30px", fontWeight: "600" }}>
            Now you can share this cool 3D animation to your social media. Click
            on the share button
          </p>
        )}
        {isVideoStart && (
          <p
            style={{ marginTop: "30px", fontWeight: "600" }}
          >{`${seconds} sec`}</p>
        )}
        {!item.shareUrl.length > 0 &&
          !isVideoStart &&
          !shareUrl.length > 0 &&
          render && (
            <button
              className="buttonMain"
              style={{ marginTop: "20px" }}
              onClick={() => startRecording()}
            >
              Capture Video
            </button>
          )}
        {!item.shareUrl.length > 0 && isVideoStart && !shareUrl.length > 0 && (
          <button
            className="buttonMain"
            style={{ marginTop: "20px" }}
            onClick={() => stopRecording()}
          >
            Stop Capturing
          </button>
        )}
        {isVideoStart && isVideoComplete && <p>Video Capture completed</p>}

        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Video Capture modal ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

        <VideoCaptureModal
          previewModal={previewModal}
          isUploadStart={isUploadStart}
          videoUrl={videoUrl}
          discardVideo={discardVideo}
          setPreviewModal={setPreviewModal}
          progress={progress}
        />
      </div>
    </>
  );
};

export default ObjLoader;
