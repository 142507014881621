import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./dashboard.css";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import ListSelect from "../../../assets/image/svg/list-select.svg";
import TutorialImg from "../../../assets/image/svg/tutorial.png";
import Email from "../../../assets/image/svg/email-support.svg";
import ScanList from "../ScanList/ScanList";
import ImageUpload from "../../FileUpload/ImageUpload";
import Profile from "../Profile/Profile";
import EmailSupport from "../emailSupport/EmailSupport";
import MenuProfile from "./../../../assets/image/svg/menuProfile.svg";
import Tutorial from "../Tutorials/Tutorial";
import Powered from "../../../assets/image/icons/power.png";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import LocalStorage from "../../../service/LocalStorage";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 440,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
});

const ResponsiveDrawer = (props) => {
  // const localStorage = new LocalStorage();
  // const search = useLocation().search;
  // const name = new URLSearchParams(search).get("access_token");
  // console.log("access_token",name);
  // useEffect(()=>{
  //   if(name){
  //     localStorage.setItem("access_token",name)
  //   }
  // },[])
  const state = useSelector((state) => state);
  // console.log("dashboardStore", state.Dashboard);

  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [toggleString, setToggleString] = useState("AllScans");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { classes, theme } = props;
  const drawer = (
    <>
      <div className="drawerMain">
        <div>
          <div className={classes.toolbar} />
          <Tooltip title="Create New" arrow>
            <button
              className="buttonMain"
              style={{ width: "100%" }}
              onClick={() =>
                dispatch({ type: "Dashboard", payload: "CreateNew" })
              }
            >
              <i className="fa fa-plus"></i> CREATE NEW
            </button>
          </Tooltip>

          <div style={{ marginTop: "10px" }}>
            <Tooltip title="Scans" arrow>
              <div
                className={
                  state.DashboardData === "AllScans"
                    ? "listSelect backgroundColor"
                    : "listSelect"
                }
                onClick={() =>
                  dispatch({ type: "Dashboard", payload: "AllScans" })
                }
              >
                <img src={ListSelect} alt="AllScans" />
                <p>Scans</p>
              </div>
            </Tooltip>

            {/* <div
            className={
              state.DashboardData === "RateUs"
                ? "listSelect backgroundColor"
                : "listSelect"
            }
            onClick={() =>  dispatch({  type: "Dashboard",payload:'RateUs' })}
          >
            <img src={Rate} />
            <p>Rate Us</p>
          </div> */}
            <Tooltip title="Tutorials" arrow>
              <div
                className={
                  state.DashboardData === "Tutorial"
                    ? "listSelect backgroundColor"
                    : "listSelect"
                }
                onClick={() =>
                  dispatch({ type: "Dashboard", payload: "Tutorial" })
                }
              >
                <img
                  src={TutorialImg}
                  alt="tutorial"
                  style={{ width: "auto", height: "23px" }}
                />
                <p style={{marginLeft: "19px"}}>Tutorials</p>
              </div>
            </Tooltip>

            <Tooltip title="Email Support" arrow>
              <div
                className={
                  state.DashboardData === "EmailSupport"
                    ? "listSelect backgroundColor"
                    : "listSelect"
                }
                onClick={() =>
                  dispatch({ type: "Dashboard", payload: "EmailSupport" })
                }
              >
                <img src={Email} alt="emailSupport" />
                <p>Email Support</p>
              </div>
            </Tooltip>

            {/* <Tooltip title="My Profile" arrow>
          <div
            className={
              state.DashboardData === "MyProfile"
                ? "listSelect backgroundColor"
                : "listSelect"
            }
            onClick={() =>
              dispatch({ type: "Dashboard", payload: "MyProfile" })
            }
          >
            <img src={MenuProfile} />
            <p style={{ marginLeft: "24px" }}>My Profile</p>
          </div>
          </Tooltip> */}
          </div>
        </div>
        {/* <div className="dashImage">
          <img src={Powered} alt="power" />
        </div> */}
      </div>
    </>
  );

  return (
    <div
      className={classes.root}
      style={{ height: "100vh", backgroundColor: "white" }}
    >
      <Hidden mdUp>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          className={classes.navIconHide}
          id="mobile"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <main
        className={classes.content}
        style={{ overflowX: "auto", padding: "0px" }}
      >
        {/* <div className={classes.toolbar} /> */}
        {/* <ImageUpload /> */}
        {state.DashboardData === "AllScans" && <ScanList />}
        {state.DashboardData === "MyProfile" && <Profile />}
        {state.DashboardData === "EmailSupport" && <EmailSupport />}
        {state.DashboardData === "Tutorial" && <Tutorial />}
        {state.DashboardData === "CreateNew" && (
          <ImageUpload setToggleString={setToggleString} />
        )}
      </main>
    </div>
  );
};

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
