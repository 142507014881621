import React, { useState, useEffect } from "react";
import "./profile.css";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { TailSpin } from "react-loader-spinner";
import API from "../../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Tooltip from "@mui/material/Tooltip";
import {successToast,errorToast,infoToast,warnToast} from "../../Toast/Toast"

function Profile() {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const [isChange, setIsChange] = useState(true);
  const [fullName, setFullName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const [image, setImage] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [editTrue, setEditTrue] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleToggle = () => {
    setIsChange(false);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.replace(/\s/g, "").length < 6) {
      errors.password = "Minimum six characters password required";
    }

    // else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(values.password)) {
    //   errors.password = 'Minimum eight characters, at least one letter and one number';
    // }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Password is required";
    } else if (values.confirmPassword.replace(/\s/g, "").length < 6) {
      errors.confirmPassword = "Minimum six characters password required";
    }

    // else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(values.confirmPassword)) {
    //   errors.confirmPassword = 'Minimum eight characters, at least one letter and one number';
    // }
    else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password and Confirm password does not match.";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: (values) => {
      // console.log(values);

      const options = {
        email: userData.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      axios
        .post(`${API.resetPassword}`, options)
        .then((res) => {
          
          if (res.data.code === 1) {
            
            errorToast(res.data.message)
            
            setLoading(false);
          } else {
            
            localStorage.setItem("token", res.data.data.token);

            successToast(res.data.message)
            // setPassword("");
            // setConfirmPassword("");
            setLoading(false);
            setTimeout(() => {
              setIsChange(true);
            }, 1200);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  });

  const Input = styled("input")({
    display: "none",
  });

  const update_profile = async () => {
    const form = new FormData();
    form.append("profile", image);

    axios
      .post(`${API.fileUpload}`, form)
      .then((res) => {
        profileUpdate(res.data.data.filename);
      })
      .catch((err) => {
        console.log("Profile error==>", err);
      });
  };



  const profileUpdate = (e) => {
    if (
      lastName.replace(/\s/g, "").length === 0 ||
      fullName.replace(/\s/g, "").length === 0
    ) {
      
      errorToast("Both First and Last name required.")
    } else {
      const options = {
        userId: userData.userId,
        fullName: fullName.trim(),
        last_name: lastName.trim(),
        email: userData.email,
        profileImage: e,
      };

      const options2 = {
        userId: userData.userId,
        fullName: fullName.trim(),
        last_name: lastName.trim(),
        email: userData.email,
      };

      axios
        .put(`${API.updateProfile}`, image ? options : options2)
        .then((res) => {
          // console.log("Profile Data==>", res.data);
          
          successToast(res.data.message)
          profileFetch();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function profileFetch() {
    const token = localStorage.getItem("token");
    const headers = {
      "x-access-token": token,
    };
    console.log(headers);
    axios
      .get(
        `${API.profileDetails}?userId=${userData.userId}`,
        { headers }
      )
      .then((res) => {
        
        localStorage.setItem("userInfo", JSON.stringify(res.data.data));
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      })
      .catch((err) => {
        console.log("ProfileFetch error==>", err);
      });
  }

  return (
    <>
      <div className="ScanContainer">
        <h4 onClick={() => setIsChange(true)} style={{ cursor: "pointer" }}>
          Profile
        </h4>

        {isChange ? (
          <>
            <div className="detailsContainer">
              <div className="uploadCount">
                <label htmlFor="icon-button-file">
                  {editTrue ? (
                    <>
                      <Input
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])}
                        id="icon-button-file"
                        type="file"
                      />

                      <img
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : userData?.profileImg
                        }
                        alt="man"
                        className="imageStyle"
                      />
                      <AddAPhotoIcon
                        className="editIcon"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : userData?.profileImg
                        }
                        alt="man"
                        className="imageStyle"
                      />
                      {/* <AddAPhotoIcon /> */}
                    </>
                  )}
                </label>
                

                <div className="profileMain">
                  <div className="profileSame">
                    {!editTrue ? (
                      <div className="w-100">
                        <div className="d-flex align-items-center mb-3">
                          <p className="fullName">First Name</p>
                          <input
                            type="text"
                            className="fullNameInput"
                            disabled
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <p className="fullName">Last Name</p>
                          <input
                            type="text"
                            className="fullNameInput"
                            disabled
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="w-100">
                        <div className="d-flex align-items-center mb-3">
                          <p className="fullName">First Name</p>
                          <input
                            type="text"
                            className="fullNameInput"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <p className="fullName">Last Name</p>
                          <input
                            type="text"
                            className="fullNameInput"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  
                  <div className="profileSame">
                    <p className="fullName">Email Address</p>
                    <input
                      type="text"
                      className="emailInput"
                      value={userData.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="changePass">
                  <Tooltip title="Change Password" arrow>
                    <span onClick={handleToggle}>Change password ?</span>
                  </Tooltip>
                </div>

                {!editTrue ? (
                  <Tooltip title="Edit Profile" arrow>
                    <button
                      className="buttonMain "
                      style={{
                        width: "80%",
                      }}
                      // onClick={(e) => (image ? update_profile(e) : profileUpdate())}
                      onClick={(e) => setEditTrue(true)}
                    >
                      EDIT PROFILE
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Update Profile">
                    <button
                      className="buttonMain "
                      style={{
                        // marginLeft: "14%",
                        // marginRight: "16%",
                        // marginTop: "2%",
                        width: "80%",
                      }}
                      onClick={(e) =>
                        image ? update_profile(e) : profileUpdate()
                      }
                    >
                      UPDATE PROFILE
                    </button>
                  </Tooltip>
                )}
              </div>

              {/* LeftSide..............................> */}
              {/* <div className="infoCountTitle">
              <div></div>
            </div> */}
            </div>
          </>
        ) : (
          <>
            {loading ? (
              <div className="loading">
                <TailSpin  height={80} width={80} color="#255FFF"/>
              </div>
            ) : (
              <>
                <div>
                  <ToastContainer autoClose={1000} draggableDirection="y" />
                </div>
                <div
                  className="detailsContainer"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="uploadCount">
                    <div className="profileMain">
                      <form>
                        <div className="same">
                          <p className="font_weight">New Password </p>
                          <input
                            type="password"
                            className="fnameInputs"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            // onBlur={formik.handleBlur}
                            placeholder="New Password"
                            name="password"
                            // onChange={(e) => setPassword(e.target.value)}
                          />

                          <p className="validationError">
                            {formik.errors.password &&
                            formik.touched.password ? (
                              <div>{formik.errors.password}</div>
                            ) : null}
                          </p>
                        </div>

                        <div className="same">
                          <p className="font_weight">Confirm New Password </p>
                          <input
                            type="password"
                            className="fnameInputs"
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                            // onBlur={formik.handleBlur}
                            placeholder="Confirm Password"
                            name="confirmPassword"
                          />

                          <p className="validationError">
                            {formik.errors.confirmPassword &&
                            formik.touched.confirmPassword ? (
                              <div>{formik.errors.confirmPassword}</div>
                            ) : null}
                          </p>
                        </div>
                      </form>
                    </div>

                    <div className="profileMain d-flex justify-content-center align-items-center">
                      <Tooltip title="Change Password" arrow>
                        <button
                          className="buttonMain "
                          style={{ width: "100%" }}
                          id="changepass"
                          onClick={formik.handleSubmit}

                          // onClick={(e) => handlePasswordChange(e)}
                        >
                          Change Password
                        </button>
                      </Tooltip>
                    </div>
                  </div>

                  {/* LeftSide..............................> */}
                  {/* <div className="infoCountTitle">
                  <div></div>
                </div> */}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Profile;
