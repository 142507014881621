import React, { useState, useEffect, useRef } from "react";
import { useHistory, Prompt } from "react-router-dom";
import Dropzone from "react-dropzone";
import "./fileUpload.css";
import axios from "axios";
import axiosRetry from "axios-retry";
import LocalStorage from "../../service/LocalStorage";

import CheckIcon from "@mui/icons-material/Check";
import ImagePagination from "./ImagePagination";
import API from "../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Add from "./../../assets/image/svg/add-empty.svg";
import { successToast, errorToast, infoToast } from "../Toast/Toast";
import { Mixpanel } from "../MixPanel/MixPanel";

var hit = 0;
var status;
var loopIndex = 0;
var inId = 0;
// var progress = 0;

const ImageUpload = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  // console.log("redux", state.UploadingData);

  const localStorage = new LocalStorage();
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  axiosRetry(axios, { retries: 3 });
  const history = useHistory();

  const [images, setImages] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [progress, setProgress] = useState(0);
  const [s3Url, setS3Url] = useState([]);
  const [isuploadStart, setIsUploadStart] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isUploadEnd, setIsUploadEnd] = useState(false);
  const [totalChunks, setTotalChunks] = useState(0);

  const [looplength, setLoopLength] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const headingRef = useRef("");
  const descRef = useRef("");
  const videoElem = useRef();
  const [thumbnail, setThumbnail] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // to overcome the initialiazation of variable from start everytime when coming from another dashboard page
    hit = 0;
    loopIndex = 0;
    inId = 0;
  }, []);

  useEffect(() => {
    let size = 0;
    let total_size_temp = 0;
    imageArray.map((item, i) => {
      size += parseFloat(Math.ceil(item.size / 5242880));
      total_size_temp += item.size;
    });
    // console.log("totalSize", total_size_temp);
    setTotalChunks(size);
    setTotalSize(total_size_temp);
    // console.log("sizeTemp==>",total_size_temp);
  }, [imageArray.length]);

  const CompleteTask = (id) => {
    const options = {
      initiateId: id,
      userId: userData.userId,
      dataSetType: 2,
      collect_name: headingRef.current.value,
      collect_notes: descRef.current.value,
    };
    axios
      .post(`${API.completeTask}`, options)
      .then((res) => {
        setIsUploadEnd(true);
        setIsUploadStart(false);
        setS3Url([]);
        inId = 0;
        loopIndex = 0;

        hit = 0;

        successToast("Upload Process Completed");
        setTimeout(() => {
          // props.setToggleString("AllScans");
          // dispatch({ type: "Dashboard", payload: "AllScans" });
          window.location.reload();
        }, 2000);
        Mixpanel.identify(userData.userId);
        Mixpanel.track("Upload Success");
        Mixpanel.people.set({
          $first_name: userData.first_name,
          $last_name: userData.last_name,
          $email: userData.data.email,
        });
      })
      .catch((err) => {
        console.log(err);
        Mixpanel.track("Upload Failed");
      });
  };

  const uploadThumbnail = (file) => {
    const form = new FormData()
    form.append("initiateId",inId)
    form.append("userId",userData.userId)
    form.append("thumbnail",file)

    axios.post(`${API.uploadThumbnail}`,form).then((res) => {
      console.log("thumbnailSuccess",res.data);
    }).then((err)=>{
      console.log(err);
    })
  }

  const captureThumbnail = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoElem.current.videoWidth;
    canvas.height = videoElem.current.videoHeight;

    canvas
      .getContext("2d")
      .drawImage(
        videoElem.current,
        0,
        0,
        videoElem.current.videoWidth,
        videoElem.current.videoHeight
      );

    setThumbnail(canvas.toDataURL(), "image.png");
    
    fetch(canvas.toDataURL())
      .then((res) => res.blob())
      .then((blob) => {
        const NewFile = new File([blob], "video_thumbnail", {
          type: "image/png",
        });
        uploadThumbnail(NewFile)
       
       
      });
  };

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  Adding of photos as soon as added ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  function onlySupport(array) {
    return array.every((img) => {
      // return typeof element === 'string';
      return ["jpg", "jpeg", "mp4", "quicktime"].includes(img.type.slice(6));
    });
  }
  useEffect(() => {
    if (images.length < 1) {
      return;
    }
    const newImage = [];

    if (onlySupport(images)) {
      images.forEach((image) => newImage.push(image));
      setImageArray([...imageArray, ...newImage]);
    } else {
      errorToast("Unsupported File Format");
    }
  }, [images]);

  useEffect(() => {
    setLoopLength(imageArray.length);
  }, [imageArray]);

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  Image change on drop or select ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  const imageChangeHandler = (item) => {
    const acceptedVideo = item.filter((item) => {
      return item.type.slice(0, 5) === "video";
    });

    const singleVideo = imageArray.filter((item) => {
      return item.type.slice(0, 5) === "video";
    });

    // console.log("accepted", acceptedVideo.length);
    // console.log("item", item.length);
    // console.log("singleVideo", singleVideo.length);

    if (acceptedVideo.length > 1) {
      errorToast("Only one video to be upload for single process.");
    } else if (acceptedVideo.length > 0 && item.length > 1) {
      errorToast("Only one video to be upload for single process.");
    } else if (singleVideo.length === 1) {
      errorToast(
        "Discard the previous video first to select another images or video"
      );
    } else if (
      acceptedVideo.length > 0 &&
      item.length > 0 &&
      imageArray.length > 1
    ) {
      errorToast("Only one video to be upload for single process.");
    } else if (acceptedVideo.length > 0 && imageArray.length > 0) {
      errorToast("Only one video to be upload for single process.");
    } else {
      setImages([...item]);
    }
  };

  const createChunks = (
    file,
    cSize /* cSize should be byte 1024*1 = 1KB */
  ) => {
    let startPointer = 0;
    let endPointer = file.size;
    let chunks = [];
    while (startPointer < endPointer) {
      let newStartPointer = startPointer + cSize;
      chunks.push(file.slice(startPointer, newStartPointer));
      startPointer = newStartPointer;
    }
    return chunks;
  };

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Uploading photos ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // ~~~~~~~~~~~~~~~~~~~~~~ Uploading first file index ~~~~~~~~~~~~~~``

  const uploadAsset = () => {
    const singleVideo = imageArray.filter((item) => {
      return item.type.slice(0, 5) === "video";
    });
    if (singleVideo.length > 0) {
      videoUpload();
    } else {
      upload();
    }
  };

  const upload = () => {
    // setIsUploadStart(true);
    setS3Url([]);

    if (
      imageArray.length > 4 &&
      headingRef.current.value.replace(/\s/g, "").length >= 2
      // descRef.current.value.replace(/\s/g, "").length > 0 &&
    ) {
      setIsUploadStart(true);
      const chunks = createChunks(imageArray[0], 5 * 1024 * 1024);
      // console.log("chunk", chunks[0]);

      const form = new FormData();
      form.append("userId", userData.userId);
      imageArray[0].type.slice(0, 5) === "image"
        ? form.append("fileType", "images")
        : form.append("fileType", "video");
      form.append("totalFilesToUpload", imageArray.length);
      form.append("fileIndex", 0);
      form.append("chunkIndex", 0);
      form.append("totalChunks", chunks.length);
      form.append("fileSize", totalSize);
      form.append("file", chunks[0]);
      form.append("fileExt", imageArray[0].type.slice(6));
      form.append("collect_name", headingRef.current.value);
      form.append("collect_notes", descRef.current.value);
      form.append("fileName", imageArray[0].name.replace(/\.[^/.]+$/, ""));

      postFirst(form);
    }
    if (
      imageArray.length > 4 &&
      headingRef.current.value.replace(/\s/g, "").length < 2
      // && descRef.current.value.replace(/\s/g, "").length <= 0
    ) {
      errorToast("Title cannot be less than 2 characters");
      setIsUploadStart(false);
    }
    if (
      imageArray.length <= 0 &&
      // descRef.current.value.replace(/\s/g, "").length <= 0 &&
      headingRef.current.value.replace(/\s/g, "").length <= 0
    ) {
      errorToast("Select File to upload !!");
      setIsUploadStart(false);
    }
    // if (
    //   descRef.current.value.replace(/\s/g, "").length === 0 ||
    //   headingRef.current.value.replace(/\s/g, "").length === 0
    // ) {
    //   errorToast("Add Both Title and description to proceed !!");
    //   setIsUploadStart(false);
    // }
    if (descRef.current.value.length > 200) {
      errorToast("Description can be 200 words long only");
      setIsUploadStart(false);
    }
    if (headingRef.current.value.length > 100) {
      errorToast("Title can be 100 words long only");
      setIsUploadStart(false);
    }
    if (
      imageArray.length <= 0 &&
      // descRef.current.value.replace(/\s/g, "").length > 0 &&
      headingRef.current.value.replace(/\s/g, "").length > 0
    ) {
      errorToast("Select File to upload !!");
      setIsUploadStart(false);
    }
    if (imageArray[0].type.slice(0, 5) === "image" && imageArray.length < 5) {
      console.log("arrayLength", imageArray.length);
      errorToast("Minimum 5 images required  !!");
    }
  };

  const postFirst = (form) => {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    const url = `${API.uploadAssets}`;
    if (isOnline) {
      axios
        .post(url, form)
        .then((res) => {
          console.log("First chunk Response===>", res.data);
          res.data.data.S3FileUrl.length > 0 &&
            setS3Url([res.data.data.S3FileUrl.length]);
          res.data.data.S3FileUrl.length > 0 &&
            CompleteTask(res.data.data.initiateId);
          uploadAll(res.data.data.initiateId);
          inId = res.data.data.initiateId;
        })
        .catch((err) => {
          console.log("Error ====>", err);
        });
    }
  };

  // ~~~~~~~~~~~~~~~~~~ Uploading file index other than one ~~~~~~~~~~~~~~``

  async function uploadAll(id) {
    // console.log("remaining", imageArray.length, imageArray);
    let len = imageArray.length;
    // console.log("after back to online ", loopIndex, looplength);

    for (loopIndex; loopIndex < imageArray.length; loopIndex) {
      if (window.navigator.onLine) {
        const chunks = createChunks(imageArray[loopIndex], 5 * 1024 * 1024);
        let i = 0;

        if (window.navigator.onLine) {
          let isTrue = true;
          let totalHit = 0;
          let subhit = 0;
          while (i < chunks.length) {
            const form = new FormData();
            form.append("initiateId", id);
            form.append("userId", userData.userId);
            form.append("fileType", "images");
            form.append("totalFilesToUpload", looplength);
            form.append("fileIndex", loopIndex);
            form.append("chunkIndex", i);
            form.append("totalChunks", chunks.length);
            form.append("fileSize", totalSize);
            form.append("file", chunks[i]);
            // form.append("fileExt", imageArray[loopIndex].type.slice(6));
            form.append("fileExt", imageArray[loopIndex].name.split(".").pop());
            form.append(
              "fileName",
              imageArray[loopIndex].name.replace(/\.[^/.]+$/, "")
            );
            form.append("collect_name", headingRef.current.value);
            form.append("collect_notes", descRef.current.value);

            if(loopIndex===1 ){
              uploadThumbnail(imageArray[0])
            }

            // console.log("hit inside loop", hit);
            const data = await axios
              .post(`${API.uploadAssets}`, form)
              .then((res) => {
                res.data.data.S3FileUrl.length > 0 &&
                  CompleteTask(res.data.data.initiateId);
                res.data.data.S3FileUrl.length > 0 && setImageArray([]);
                res.data.data.S3FileUrl.length > 0 &&
                  setS3Url([res.data.data.S3FileUrl.length]);
                // hit++;
                // i++;
                return res.data.data;
              })
              .catch((err) => {
                // i++;
                // hit++;

                isTrue = false;
                return;
              });
            i++;
            hit++;
            // setHit(hit+1)
          }
          if (isTrue) {
            loopIndex++;
            setProgress(
              progress > 100 ? progress : Math.floor((hit / totalChunks) * 100)
            );
          } else {
            hit -= chunks.length;
          }
        } else {
          break;
        }
      } else {
        // console.log("break called", imageArray);
        break;
      }
    }
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  Deleting Selected Images ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const deleteImage = (img) => {
    const newData = imageArray.filter((item) => {
      return img !== item;
    });
    setImageArray(newData);
  };

  // for reload and closing alert
  useEffect(() => {
    if (isuploadStart) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };

      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [isuploadStart]);

  // ~~~~~~~~~~~~~~~~~~~~~~~~~ online and offline of user handling ~~~~~~~~~~~~~~~~~~~~~

  const online = () => {
    // console.log("We are online !!!!");
    status = true;
    setIsOnline(true);
  };
  const offline = () => {
    // console.log("We are offline !!!!");
    status = false;
    setIsOnline(false);
  };

  useEffect(() => {
    // console.log("status==>", status);
    const singleVideo = imageArray.filter((item) => {
      return item.type.slice(0, 5) === "video";
    });
    if (status) {
      // console.log("single or not", singleVideo.length > 0);

      singleVideo.length === 1 ? videoUpload() : uploadAll(inId);
      s3Url.length > 0 && CompleteTask(inId);
      // videoUpload()
      // uploadAll(inId)
    }
  }, [status]);

  useEffect(() => {
    window.addEventListener("online", online);
    window.addEventListener("offline", offline);

    return () => {
      window.removeEventListener("offline", online);
      window.removeEventListener("online", offline);
    };
  }, []);

  const discard = () => {
    headingRef.current.value = "";
    descRef.current.value = "";
    setImageArray([]);
  };

  const videoUpload = async () => {
    // console.log("video uploadcalled", loopIndex);
    if (
      imageArray.length > 0 &&
      // descRef.current.value.replace(/\s/g, "").length > 0 &&
      headingRef.current.value.replace(/\s/g, "").length >= 2 &&
      descRef.current.value.length < 200 &&
      headingRef.current.value.length < 100
    ) {
      setIsUploadStart(true);
      const chunks = createChunks(imageArray[0], 5 * 1024 * 1024);

      if (window.navigator.onLine) {
        while (loopIndex < chunks.length) {
          if (window.navigator.onLine) {
            // console.log("inside while", window.navigator.onLine);
            // console.log("remainingChunk==>", chunks[loopIndex]);
            const form = new FormData();
            inId && form.append("initiateId", inId);
            form.append("userId", userData.userId);
            form.append("fileType", "video");
            form.append("totalFilesToUpload", 1);
            form.append("fileIndex", 0);
            form.append("chunkIndex", loopIndex);
            form.append("totalChunks", chunks.length);
            form.append("fileSize", totalSize);
            form.append("file", chunks[loopIndex]);
            form.append("fileExt", imageArray[0].name.split(".").pop());

            form.append(
              "fileName",
              imageArray[0].name
                .split(".")
                .shift()
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .replace(/\s/g, "")
            );
            form.append("collect_name", headingRef.current.value);
            form.append("collect_notes", descRef.current.value);
            // hit+=1

            if(loopIndex ===1){
              captureThumbnail()
            }

            // console.log("hit inside loop", hit);
            const data = await axios
              .post(`${API.uploadAssets}`, form)
              .then((res) => {
                inId = res.data.data.initiateId;
                res.data.data.S3FileUrl.length > 0 &&
                  CompleteTask(res.data.data.initiateId);
                // res.data.data.S3FileUrl.length > 0 && setIsUploadStart(false);
                res.data.data.S3FileUrl.length > 0 && setImageArray([]);
                loopIndex++;
                hit++;
                setProgress(
                  progress > 100
                    ? progress
                    : Math.floor((hit / totalChunks) * 100)
                );
                return res.data.data;
              })
              .catch((err) => {
                // loopIndex--;
                console.log("videUploadEror", err);
                return;
              });

            // setHit(hit+1)
          } else {
            break;
          }
          // imageArray.shift();
        }
      } else {
        // console.log("break called", imageArray);
        return;
      }
    }
    if (
      imageArray.length > 0 &&
      // descRef.current.value.replace(/\s/g, "").length === 0 &&
      headingRef.current.value.replace(/\s/g, "").length < 2
    ) {
      errorToast("Title cannot be less than 2 characters");
      setIsUploadStart(false);
    }
    // if (
    //   descRef.current.value.replace(/\s/g, "").length === 0 ||
    //   headingRef.current.value.replace(/\s/g, "").length === 0
    // ) {
    //   errorToast("Add Both Title and description to proceed !!");
    //   setIsUploadStart(false);
    // }
    if (
      imageArray.length <= 0 &&
      // descRef.current.value.replace(/\s/g, "").length <= 0 &&
      headingRef.current.value.replace(/\s/g, "").length <= 0
    ) {
      errorToast("Select File to upload !!");
      setIsUploadStart(false);
    }
    if (descRef.current.value.length > 200) {
      errorToast("Description can be 200 words long");
      setIsUploadStart(false);
    }
    if (headingRef.current.value.length > 100) {
      errorToast("Title can be 100 words long");
      setIsUploadStart(false);
    }
    if (
      imageArray.length <= 0 &&
      // descRef.current.value.replace(/\s/g, "").length > 0 &&
      headingRef.current.value.replace(/\s/g, "").length > 0
    ) {
      errorToast("Select File to upload !!");
      setIsUploadStart(false);
    }
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} draggableDirection="y" />
      </div>
      <div className="allScanContainer">
        <h4>Create New</h4>
        <div className="detailsContainerUpload">
          {isuploadStart && (
            <Prompt message="You have unsaved changes, are you sure you want to leave?" />
          )}
          <div className="uploadCont">
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {imageArray.length > 0 &&
                imageArray[0].type.slice(0, 5) === "image" && (
                  <ImagePagination
                    imageArray={imageArray}
                    deleteImage={deleteImage}
                    isuploadStart={isuploadStart}
                  />
                )}
              {imageArray.length > 0 &&
                imageArray[0].type.slice(0, 5) === "video" && (
                  <video
                    style={{
                      width: "100%",
                      height: "370px",
                      borderRadius: "10px",
                    }}
                    ref={videoElem}
                  >
                    <source src={URL.createObjectURL(imageArray[0])} />
                  </video>
                )}
            </div>
            <div>
              {!isuploadStart && (
                <Dropzone
                  onDrop={(acceptedFiles) => imageChangeHandler(acceptedFiles)}
                  accept="image/*,video/*"
                  inputContent={(files, extra) =>
                    extra.reject
                      ? "Image, audio and video files only"
                      : "Drag Files"
                  }
                  styles={{
                    dropzoneReject: {
                      borderColor: "red",
                      backgroundColor: "#DAA",
                    },
                    inputLabel: (files, extra) =>
                      extra.reject ? { color: "red" } : {},
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()} className="dropZone">
                        <div className="">
                          {" "}
                          <input {...getInputProps()} />
                          <img src={Add} />
                        </div>
                        <div className="dragDrop">
                          Drag & Drop or Add Video/ Photos
                        </div>
                        <div className="imageType">.jpg, .jpeg, mp4, MOV</div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </div>
          </div>

          {/* RightSide..............................> */}
          <div className="infoContTitle">
            <div>
              <div>
                <p className="title">Title</p>
                <input
                  ref={headingRef}
                  type="text"
                  readOnly={isuploadStart ? true : false}
                  placeholder="Add title"
                  className="discription"
                />
              </div>
              <div>
                <p className="title">Description</p>
                <textarea
                  ref={descRef}
                  readOnly={isuploadStart ? true : false}
                  type="text"
                  placeholder="Add description"
                  className="discription"
                  rows="6"
                />
              </div>
              {!window.navigator.onLine && <p>Internet Connection error</p>}
              {isuploadStart === true ? (
                <div>
                  <p style={{ marginLeft: "4%", marginTop: "4%" }}>
                    Uploading your scan.
                  </p>
                </div>
              ) : (
                ""
              )}
              {isUploadEnd && (
                <div
                  style={{ display: "flex", marginLeft: "4%", marginTop: "4%" }}
                >
                  {" "}
                  <p>Upload Complete for Processing</p>{" "}
                  <CheckIcon style={{ color: "#C6D5FF", marginLeft: "4%" }} />{" "}
                </div>
              )}
              {isuploadStart && (
                <div>
                  <div style={{ padding: "10px 5px" }}>
                    <div className="progress" style={{ width: "100%" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width: `${progress}%`,
                          backgroundColor: "#C6D5FF",
                        }}
                      >
                        {progress}%
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isuploadStart && (
                <div style={{ padding: "10px 5px" }}>
                  {" "}
                  <p>
                    Please don’t leave this page until the scan is uploaded.
                  </p>{" "}
                </div>
              )}
            </div>

            {!isuploadStart && !isUploadEnd && (
              <div className="twoButton">
                <p
                  style={{ width: "155px", marginTop: "10px" }}
                  className="signUpText"
                  onClick={() => discard()}
                >
                  <Tooltip title="Discard" arrow>
                    <span
                      style={{
                        color: " #255FFF",
                        cursor: "pointer",
                        fontSize: "15px",
                        fontWeight: "600",
                        margin: "0 12px",
                        width: "155px",
                        marginTop: "10px",
                      }}
                    >
                      DISCARD
                    </span>
                  </Tooltip>
                </p>

                <Tooltip title="Process" arrow>
                  <button
                    style={{ width: "155px" }}
                    className="buttonMain"
                    onClick={() => uploadAsset()}
                  >
                    PROCESS
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
