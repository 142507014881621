import React from 'react'
import { Modal } from "react-bootstrap";
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
  } from "react-share";
  import ContentCopyIcon from "@mui/icons-material/ContentCopy";


const SocialShareModal = ({itemDetails, shareUrl, urlCopyHandle, isCopied, show, handleClose,showDownloadModal}) => {
  return (
    <Modal className="mainModal" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className="modalTitle">Share</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h5 className="Mesh" style={{ marginBottom: "14px" }}>
                    3D Mesh
                  </h5>
                  {!localStorage.getItem("access_token") ? (
                    <a
                      href="https://sketchfab.com/oauth2/authorize/?state=123456789&response_type=token&client_id=w5iSwfvMcuc35nd0TkxagFEc7c2s5j3y7BdAqIi4&redirect_uri=https://stage.scanamaze.com&approval_prompt=auto"
                      // target="_blank"
                      className="fabButton"
                      style={{ textDecoration: "none" }}
                    >
                      Sketchfab
                    </a>
                  ) : (
                    <button
                      className="fabButton"
                      onClick={() => showDownloadModal()}
                      style={{ width: "auto" }}
                    >
                      Sketchfab
                    </button>
                  )}
                  {/* </button> */}
                </div>
                <div className="videoDiv">
                  <h5 className="Mesh">3D Video</h5>
                  {itemDetails.shareUrl.length > 0 || shareUrl.length > 0 ? (
                    <div className="d-flex">
                      <button className="fabButton">
                        <FacebookShareButton
                          url={
                            itemDetails.shareUrl
                              ? itemDetails.shareUrl
                              : shareUrl
                          }
                          description={itemDetails.description}
                          title={itemDetails.title}
                         
                        >
                          Facebook
                        </FacebookShareButton>
                      </button>
                    
                      <button className="fabButton">
                        <TwitterShareButton
                          url={
                            itemDetails.shareUrl
                              ? itemDetails.shareUrl
                              : shareUrl
                          }
                          // description={itemDetails.description}
                          // title={itemDetails.title}
                        >
                          Twitter
                        </TwitterShareButton>
                      </button>

                      <button className="fabButton">
                        <LinkedinShareButton
                          url={
                            itemDetails.shareUrl
                              ? itemDetails.shareUrl
                              : shareUrl
                          }
                          description={itemDetails.description}
                          title={itemDetails.title}
                        >
                          Linkedin
                        </LinkedinShareButton>
                      </button>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        className="fabButton"
                        onClick={() => urlCopyHandle()}
                      >
                        <ContentCopyIcon
                          style={{ fontSize: "20px", marginRight: "5px" }}
                        />
                        {!isCopied ? "Copy" : "Copied"}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <p className="Mesh">Capture Video to Share</p>
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>
  )
}

export default SocialShareModal