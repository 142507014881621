// import mixpanel from 'mixpanel-browser';
// mixpanel.init('d5a94e3b22822c8f4abb2639abf06058');

// let env_check = process.env.NODE_ENV === 'production';

// let actions = {
//   identify: (id) => {
//     if (env_check) mixpanel.identify(id);
//   },
//   alias: (id) => {
//     if (env_check) mixpanel.alias(id);
//   },
//   track: (name, props) => {
//     if (env_check) mixpanel.track(name, props);
//   },
//   people: {
//     set: (props) => {
//       if (env_check) mixpanel.people.set(props);
//     },
//   },
// };

// export let Mixpanel = actions;

import mixpanel from 'mixpanel-browser';
mixpanel.init('93d56f5686be11b9eafe687e5aacd2f6');

// let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
     mixpanel.identify(id);
  },
  alias: (id) => {
     mixpanel.alias(id);
  },
  track: (name, props) => {
     mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
       mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;