import React from "react";
import { toast } from "react-toastify";

const successToast = (string) => {
  toast.success(string, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
  });
};
const errorToast = (string) => {
  toast.error(string, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
  });
};
const infoToast = (string) => {
  toast.info(string, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
  });
};
const warnToast = (string) => {
  toast.warn(string, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
  });
};

export  { successToast,errorToast,infoToast,warnToast };
