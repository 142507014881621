import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LocalStorage from "../../../service/LocalStorage";
import "./signup.css";
import { TailSpin } from "react-loader-spinner";
import SocialLogin from "../Login/SocialLogin";
import API from "../../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import { Mixpanel } from "../../MixPanel/MixPanel";
import {
  successToast,
  errorToast,
  infoToast,
  warnToast,
} from "../../Toast/Toast";

const Signup = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const localStorage = new LocalStorage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/dashboard");
    }
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "First Name is required";
    } else if (values.firstname.length <= 2) {
      errors.firstname = "First name must be 3 character long.";
    } else if (values.firstname.length > 50) {
      errors.firstname = "First name not more than 50 character.";
    } else if (!/^[a-zA-Z\s\.]*$/i.test(values.firstname)) {
      errors.firstname = "First name must contain alphabets.";
    }
    // else if (!/^[A-Z]/.test(values.firstname)) {
    //   errors.firstname = "First letter should be uppercase";
    // }
    else if (/\s/.test(values.firstname)) {
      errors.firstname = "Blank space not allowed";
    }

    if (!values.lastname) {
      errors.lastname = "Last Name is required";
    } else if (values.lastname.length <= 2) {
      errors.lastname = "Last name must be 3 character long.";
    } else if (values.lastname.length > 50) {
      errors.lastname = "Last name not more than 50 character.";
    } else if (!/^[a-zA-Z\s\.]*$/i.test(values.lastname)) {
      errors.lastname = "Last name must contain alphabets.";
    }
    // else if (!/^[A-Z]/.test(values.lastname)) {
    //   errors.lastname = "First letter should be uppercase";
    // }
    else if (/\s/.test(values.lastname)) {
      errors.lastname = "Blank space not allowed";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    } else if (/\s/.test(values.email)) {
      errors.lastname = "Blank space not allowed";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
    },
    validate,
    onSubmit: (values) => {
      if (
        values.email.length > 0 &&
        values.firstname.length > 0 &&
        values.lastname.length > 0
      ) {
        setLoading(true);
        const first = values.firstname.replace(/\b\w/g, function (c) {
          return c.toUpperCase();
        });
        const last = values.lastname.replace(/\b\w/g, function (c) {
          return c.toUpperCase();
        });
        const options = {
          first_name: first,
          last_name: last,
          orgnization_name: "Test Company",
          email: values.email,
          tnc: true,
        };

        console.log("signupOptions",options);

        axios
          .post(`${API.signUp}`, options)
          .then((res) => {
            // console.log("Signup success ===>", res.data);
            if (res.data.code === 1) {
              errorToast(res.data.data[0].msg);
              Mixpanel.track("Registration failed");
              setLoading(false);
            } else {
              setLoading(false);

              successToast(res.data.message);
              // console.log("successMsg",res.data.message);
              Mixpanel.identify(res.data.data.userId);
              Mixpanel.track("Registration Successfully");
              Mixpanel.people.set({
                $first_name: res.data.data.first_name,
                $last_name: res.data.data.last_name,
                $email: res.data.data.email,
              });

              setTimeout(() => {
                history.push("/login");
              }, 1500);
              // window.location.reload();
            }
          })
          .catch((err) => {
            console.log("Signup error ===>", err);
          });
      } else {
        errorToast("All Fields are required!!");
      }
    },
  });

  return (
    <div className="mainContainer" onSubmit={formik.handleSubmit}>
      {loading ? (
        <div className="loading">
          <TailSpin height={80} width={80} color="#255FFF" />
        </div>
      ) : (
        <>
          <div>
            <ToastContainer autoClose={1000} draggableDirection="y" />
          </div>
          <div className="formContainer">
            <form className="form">
              <h2>Sign Up</h2>

              <label>First Name</label>
              <input
                type="text"
                required
                name="firstname"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                // onBlur={formik.handleBlur}
                className="input"
              />
              <p className="validationError">
                {formik.errors.firstname && formik.touched.firstname ? (
                  <div>{formik.errors.firstname}</div>
                ) : null}

                {/* {formik.errors.firstname ? (
                  <div>{formik.errors.firstname}</div>
                ) : null} */}
              </p>
              <label>Last Name</label>
              <input
                type="text"
                required
                name="lastname"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                // onBlur={formik.handleBlur}
                className="input"
              />
              <p className="validationError">
                {formik.errors.lastname && formik.touched.lastname ? (
                  <div>{formik.errors.lastname}</div>
                ) : null}
                {/* {formik.errors.lastname ? (
                  <div>{formik.errors.lastname}</div>
                ) : null} */}
              </p>
              <label>Email Address</label>
              <input
                type="email"
                required
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                // onBlur={formik.handleBlur}
                className="input"
              />
              <p className="validationError">
                {/* {formik.errors.email ? <div>{formik.errors.email}</div> : null} */}
                {formik.errors.email && formik.touched.email ? (
                  <div>{formik.errors.email}</div>
                ) : null}
              </p>
              <p
                className="forgotpass"
                onClick={() => history.push("forgotpass")}
              >
                <Tooltip title="Forgot Password" arrow>
                  <span>Forgot Password?</span>
                </Tooltip>
              </p>
              <Tooltip title="Sign Up" arrow>
                <button
                  className="buttonMain"
                  type="button"
                  style={{ marginTop: "15px" }}
                  onClick={formik.handleSubmit}
                >
                  SIGN UP
                </button>
              </Tooltip>
            </form>
            <div>
              <p style={{ marginTop: "12px" }}>
                {" "}
                Already have an account ?{" "}
                <Tooltip title="Sign In" arrow>
                  <span onClick={() => history.push("/login")} className="span">
                    Sign In
                  </span>
                </Tooltip>
              </p>
            </div>

            <SocialLogin loading={loading} setLoading={setLoading}/>
          </div>
        </>
      )}
    </div>
  );
};

export default Signup;
