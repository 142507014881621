import React, { useEffect, useState } from "react";
import "./home.css";
import GetStarted from "../Home/GetStarted";
import Face from "../../../assets/image/home/Frame1.png";
import Scooty from "../../../assets/image/home/Frame6.png";
import GuardRoom from "../../../assets/image/home/Frame3.png";
import Pot from "../../../assets/image/home/Frame4.png";
import Pot2 from "../../../assets/image/home/Frame5.png";
import Speaker from "../../../assets/image/home/Frame2.png";
import videoThumb from "../../../assets/image/home/videoThumb.png";

import Slider from "./Slider";
import LocalStorage from "../../../service/LocalStorage";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const localStorage = new LocalStorage();
  const history = useHistory();
  const dispatch = useDispatch();

  // const search = useLocation().search;
  // const name = new URLSearchParams(search).get("access_token");
  // console.log("access_token", name);
  // useEffect(() => {
  //   if (name) {
  //     localStorage.setItem("access_token", name);
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      console.log(window.location.hash);
      const hash = window.location.hash.substring(
        window.location.hash.indexOf("=") + 1,
        window.location.hash.indexOf("&")
      );
      if (hash) {
        console.log("hash", hash);
        localStorage.setItem("access_token", hash);
        localStorage.setItem("loginToast", "once");
        dispatch({ type: "Dashboard", payload: "AllScans" });
      }

      history.push("/dashboard");
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="homeContainer">
      <div className="firstCont">
        <img src={Face} alt="tower" style={{ marginLeft: "-23px" }} />
        <img
          src={Speaker}
          style={{ marginBottom: "12%" }}
          alt="sofa"
          className="imageNone"
        />
        <img src={GuardRoom} alt="house" className="marginRightImg" />
      </div>

      <div className="secondCont">
        <div className="headingSec">
          <h3>Recreate Your World in 3D</h3>
          <p>
            Capture photos of videos of the world around you and turn them into amazing 3D data for free!
          </p>
        </div>
        <div className="secondImageCont">
          <img src={Pot} alt="car" style={{ marginLeft: "-23px" }} />
          <img
            src={Pot2}
            style={{ position: "relative", top: "76px" }}
            alt="bike"
            className="imageNone"
          />
          <img
            src={Scooty}
            alt="hut"
            className=""
            style={{
              borderBottomRightRadius: "0px",
              borderTopRightRadius: "0px",
            }}
          />
        </div>
      </div>

      <div className="videoSec">
        <div className="textCont">
          <h3>Scan Objects, Scenes and People.</h3>
          <p className="subHeading">
          Scan your life into 3D with Scanamaze. Scan your favorite objects, scenes and people around you with any camera and get 3D data in no time. You don’t need to be a photogrammetry expert to get a 3D model on Scanamaze. Scanamaze is completely free! Getting 3D data is as simple as this:{" "}
          </p>
          {/* 
          <p className="check">
            <img src={Check} alt="check" />
            Drag and drop photos or video of the object at different angles
          </p>
          <p className="check">
            <img src={Check} alt="check" />
            Hit the process button
          </p>
          <p className="check">
            <img src={Check} alt="check" />
            Relax while we generate an amazing 3D model of your object
          </p>
          <p className="check">
            <img src={Check} alt="check" />
            Download it. Share it.
          </p>
          <p className="check">
            <img src={Check} alt="check" />
            All this for free!
          </p> */}
          <ul className="check">
            <li>
            Drag and drop photos or a video of the object from different angles
            </li>
            <li>Hit the process button</li>
            <li>Relax while we generate an amazing 3D model of your object</li>
            <li>Download it. Share it.</li>
            <li>All this for free!</li>
          </ul>
        </div>
        <div className="videoCont">
          {/* <video width="100%" height="150%" controls > <source src="https://scanamaze.com:8080/tutorials/video.mp4" type="video/mp4" /> </video> */}
          {/* <iframe
            // width="100%"
            // height="100%"
            src="https://www.youtube.com/embed/JVD1-1-qPKw?autoplay=1&loop=1&playlist=GRonxog5mbw"
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay"
          ></iframe> */}
          {/* <iframe
            src="https://www.youtube-nocookie.com/embed/cePGlMarnL4?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow=""
            allowFullScreen
          ></iframe> */}

          <video controls poster={videoThumb}>
            <source src="https://api.scanamaze.com:8081/tutorials/intro%20video_2.mp4" style={{width:"100%"}}/>
          </video>
        </div>
      </div>

      {/* <div className="testimonialsSec">
        <h3>Testimonials</h3>
        <img className="map" src={Map} />
        <Slider />
      </div> */}

      <GetStarted />
    </div>
  );
};

export default Home;
