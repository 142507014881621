import React, { useEffect, useState } from "react";
import "./category.css";
import GetStarted from "../Home/GetStarted";
import axios from "axios";
import API from "../../../service/api_endpoint";

function AboutUs() {
  const [data, setData] = useState("");
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const FaqsFetch = () => {
    axios.get(`${API.aboutUs}`)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.log("about error==>", err);
      })
  }

  useEffect(() => {
    FaqsFetch()
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="PrivacyDiv">
        <div className="PrivacyText">About Us</div>
      </div>
      <div className="page">
        <div className="pageText">
          {data && <div dangerouslySetInnerHTML={{__html: data}}></div>}
        </div>
      </div>
      <GetStarted />
    </div>
  );
}

export default AboutUs;
