
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import LocalStorage from '../../service/LocalStorage';

const PrivateRoute = ({ children, ...rest }) =>  {
    let localstorage = new LocalStorage();
    return (
      <Route
        {...rest}
        render={({ props }) =>
          (localstorage.loginStatus() === true) ? (
            children
            // <Component {...props}/>
          ) : (
            alert('Login to access this section.'),
            <Redirect
              to={{
                pathname: "/login",               
              }}
            />
          )
        }
      />
    );
  }

  export default PrivateRoute;