import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import API from "../../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import {
  successToast,
  errorToast,
  infoToast,
  warnToast,
} from "../../Toast/Toast";

const ResetPassword = (props) => {
  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.replace(/\s/g, "").length < 6) {
      errors.password = "Minimum six characters password required";
    }
    // else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(values.password)) {
    //   errors.password = 'Minimum eight characters, at least one letter and one number';
    // }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Password is required";
    } else if (values.confirmPassword.replace(/\s/g, "").length < 6) {
      errors.confirmPassword = "Minimum six characters password required";
    }
    // else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(values.confirmPassword)) {
    //   errors.confirmPassword = 'Minimum eight characters, at least one letter and one number';
    // }
    else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password and Confirm password does not match.";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: (values) => {
      console.log("values", values);

      setLoading(true);

      if (values.password !== values.confirmPassword) {
        errorToast("Password and confirm password does not match!!");
        setLoading(false);
      } else if (
        values.password.replace(/\s/g, "").length >= 6 &&
        values.confirmPassword.replace(/\s/g, "").length >= 6 &&
        values.password === values.confirmPassword
      ) {
        // passwordReset();
        const options = {
          email: name,
          password: values.password,
          confirmPassword: values.confirmPassword,
        };
        axios
          .post(`${API.resetPassword}`, options)
          .then((res) => {
            if (res.data.code === 1) {
              //
              errorToast(res.data.message);
              setLoading(false);
            } else {
              console.log("password change response===>", res.data.data);
              localStorage.setItem("token", res.data.data.token);
              profileFetch(res.data.data.token, res.data.data.userId);

              // setLoading(false);
              // setTimeout(() => {
              //   history.push("/");
              // }, 2000);
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        
        errorToast("Password must  be atleast 6 digit long")
        setLoading(false);
      }

      // setLoading(true);
    },
  });

  const history = useHistory();
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("username"); //url params
  console.log("User Name==>", name);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      console.log("token", localStorage.getItem("token"));
      history.push("/dashboard");
    }
  }, []);

  

  const profileFetch = (token, id) => {
    const headers = {
      "x-access-token": token,
    };
    axios
      .get(`${API.profileDetails}?userId=${id}`, { headers })
      .then((res) => {
        console.log("Profile Data==>", res.data.data);
        setLoading(false);
        localStorage.setItem("userInfo", JSON.stringify(res.data.data));
        setPassword("");
        setConfirmPassword("");

        successToast("Password Successfully Changed !!! ");

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log("Profile error==>", err);
      });
  };

  return (
    <div className="mainContainer">
      {loading ? (
        <div className="loading">
          <TailSpin height={80} width={80} color="#255FFF" />
        </div>
      ) : (
        <>
          <div>
            <ToastContainer autoClose={1000} draggableDirection="y" />
          </div>
          <div className="formContainer" style={{ margin: "146px auto" }}>
            <form className="form">
              <h2>Reset Password</h2>
              <label>Email</label>
              <input
                type="email"
                required
                value={name}
                className="input"
                readOnly
              />
              <label>Password</label>
              <input
                type="password"
                required
                onChange={formik.handleChange}
                value={formik.values.password}
                // onBlur={formik.handleBlur}
                placeholder="password"
                name="password"
                className="input"
              />

              <p className="validationError">
                {formik.errors.password && formik.touched.password ? (
                  <div>{formik.errors.password}</div>
                ) : null}
              </p>
              <label>Confirm Password</label>
              <input
                type="password"
                required
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                // onBlur={formik.handleBlur}
                placeholder="confirm password"
                className="input"
                name="confirmPassword"
              />

              <p className="validationError">
                {formik.errors.confirmPassword &&
                formik.touched.confirmPassword ? (
                  <div>{formik.errors.confirmPassword}</div>
                ) : null}
              </p>
              <Tooltip title="Password Update" arrow>
                <button
                  className="buttonMain"
                  style={{ marginTop: "20px" }}
                  onClick={formik.handleSubmit}
                >
                  Password Update
                </button>
              </Tooltip>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
