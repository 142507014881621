import React from 'react'
import { Modal } from "react-bootstrap";

const DownloadMeshModal = ({downloadModal, setDownloadModal, downloadModel,showUploadModal}) => {
  return (
    <Modal
              className="mainModal"
              show={downloadModal}
              onHide={() => setDownloadModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className="modalTitle">Download Mesh</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h5
                    className="Mesh"
                    style={{
                      marginBottom: "14px",
                      fontSize: "17px",
                      marginLeft: "5px",
                    }}
                  >
                    Download this 3D model to share to sketchfab.
                  </h5>
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      width: "65%",
                      marginTop: "24px",
                    }}
                  >
                    {" "}
                    <button
                      className="buttonMain"
                      onClick={() => downloadModel()}
                    >
                      Download
                    </button>
                    <button
                      className="buttonMain"
                      onClick={() => showUploadModal()}
                    >
                      Already Downloaded
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
  )
}

export default DownloadMeshModal