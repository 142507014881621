import React, { useState, useEffect, Suspense,useCallback  } from "react";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import "./pagination.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function ImagePagination({ deleteImage, imageArray, isuploadStart }) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(24);
  const [pageCount, setPageCount] = useState(0);

  const handlePageClick = (e, value) => {
    // console.log("Value",value);
    const selectedPage = value - 1;
    console.log("pageChange", selectedPage);

    setOffset(selectedPage * 24);
  };

  
  
  

  useEffect(() => {
    const slice = imageArray.slice(offset, offset + perPage);
   
    setData(slice);
    console.log(
      "Count==>",
      Math.ceil(imageArray.length / perPage),
      imageArray.length
    );
    setPageCount(Math.ceil(imageArray.length / perPage));
  }, [offset, imageArray]);

  

  return (
    <>
      <Suspense fallback={null}>
        <div className="imageSection">
          {data.map((img, index) => {
            return (
              <span key={index} className="imageDiv" style={{ margin: "auto" }}>
                <div
                  className="displayedImage"
                  // style={{
                  //   height: "80px",
                  //   width: "80px",
                  //   borderRadius: "50%",
                  //   textAlign: "center",
                  // }}
                  onClick={() => !isuploadStart && deleteImage(img)}
                >
                  {img.type.slice(0, 5) === "image" ? (
                    <img
                      src={URL.createObjectURL(img)}
                      key={index}
                      style={{ height: "85px", width: "100%" }}
                      loading="lazy"
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(img)}
                      key={index}
                      style={{ width: "77%", height: "91%" }}
                    />
                  )}
                </div>
                {
                  <span className="removeImg">
                    <IoIosRemoveCircleOutline
                      onClick={() => !isuploadStart && deleteImage(img)}
                    />
                  </span>
                }
              </span>
            );
          })}
        </div>
      </Suspense>

      {imageArray.length > 1 && (
        <Stack spacing={2}>
          <Pagination
            count={pageCount}
            // defaultPage={6}
            // boundaryCount={2}
            onChange={handlePageClick}
          />
        </Stack>
      )}
    </>
  );
}

export default ImagePagination;
