import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBMTyJVLMwngVy4bnqka53eNzNQQL-azuU",
  authDomain: "scanamaze-df6e1.firebaseapp.com",
  projectId: "scanamaze-df6e1",
  storageBucket: "scanamaze-df6e1.appspot.com",
  messagingSenderId: "909918745503",
  appId: "1:909918745503:web:5430e151b410608acf83aa",
  measurementId: "G-ENWZ26PMVX"
};

// Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export default firebase;