import axios from "axios";
import React, { useEffect, useState } from "react";
import LocalStorage from "../../../service/LocalStorage";
import ObjLoader from "../../WebViewer/ObjLoader";
import Download from "../../../assets/image/svg/download.svg";
import API from "../../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { RiDownload2Line } from "react-icons/ri";
import { FiShare2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import { TailSpin } from "react-loader-spinner";
import SocialShareModal from "../../Modals/SocialShareModal";
import DownloadMeshModal from "../../Modals/DownloadMeshModal";
import SketchfabUploadModal from "../../Modals/SketchfabUploadModal";
import {
  successToast,
  errorToast,
  infoToast,
  warnToast,
} from "../../Toast/Toast";
import PriorityIcon from "../../../assets/image/icons/ic_priority.png";
import Check from "../../../assets/image/icons/ic_checkbox.png";
import UnCheck from "../../../assets/image/icons/ic_unchecked.png";
import ErrorBoundary from "../../Shared/ErrorBoundary";

// var progress=0

const ScanItemDetails = (props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [shareUrl, setShareUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const [uploadModal, setUploadModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // ~~~~~~~~~~~~~~~~~~~ displaying mesh download modal ~~~~~~~~~~~~~~

  const showDownloadModal = () => {
    setDownloadModal(true);
    // setUploadModal(true);
    handleClose();
  };

  // ~~~~~~~~~~~~~~~~~~~~~  displaying mesh upload modal ~~~~~~~~~~~
  const showUploadModal = () => {
    setDownloadModal(false);
    setUploadModal(true);
  };

  // ~~~~~~~~~~~~~~~~~~~~  close share modal ~~~~~~~~~~~~~~~~~~~

  const closeUploadModalClose = () => {
    setUploadModal(false);
    setCategories([]);
    setShow(false);
  };

  // console.log("itemDetails===>", props.itemDetails);
  const { itemDetails } = props;
  const [userId, setUserId] = useState(null);
  const localStorage = new LocalStorage();
  const accessToken = localStorage.getItem("access_token");
  // console.log("access_token", accessToken);

  // ~~~~~~~~~~~~~~~~~~~~~~~ Upload Details ~~~~~~~~~~~~~~~~~~~~~~~~
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState(props.itemDetails.title);
  const [description, setDescription] = useState(props.itemDetails.description);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [uploadStart, setUploadStart] = useState(false);
  const [isChecked, setIsChecked] = useState(props.itemDetails.consent);
  // console.log('isChecked',props.itemDetails.consent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.seeDetails]);

  const handleFileReader = (event) => {
    if (event.target.files[0].size > 52428800) {
      warnToast(
        "Sketchfab allows uploads above 50 MB in size only for Plus, Pro or Company accounts."
      );
      setFile(null);
    } else {
      // console.log(event.target.files[0]);
      setFile(event.target.files[0]);
    }
  };

  const tagshandler = (e) => {
    let array = [];
    const tagsArray = e.target.value.replace(/\s/g, "").split(",");
    tagsArray.map((item) => {
      const pushData = " " + item;
      array.push(pushData);
    });

    setTags([" Scanamaze", ...array]);
  };

  const uploadModel = () => {
    if (
      title.replace(/\s/g, "").length > 0 &&
      description.replace(/\s/g, "").length > 0 &&
      file !== null &&
      // categories.length > 0 &&
      tags.length > 1
    ) {
      setUploadStart(true);
      const form = new FormData();
      form.append("name", title);
      form.append("description", description);
      form.append("modelFile", file);
      // form.append("categories", categories);
      form.append("tags", tags);
      // form.append("private", 0);
      // form.append("password", "Tr0b4dor&3");
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      };

      const config = {
        headers,
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
          // console.log("progress", percentCompleted);
        },
      };

      axios
        .post("https://api.sketchfab.com/v3/models", form, config)
        .then((res) => {
          successToast("Your Sketchfab upload was successful.");
          localStorage.removeItem("access_token");
          // console.log("uploadresponse", res);
          setUploadStart(false);
          closeUploadModalClose();
        })
        .catch((err) => {
          errorToast("An Error Occured, please try again.");
          setUploadStart(false);
          console.log("Upload response", err);
        });
    } else {
      errorToast("All fields are mandatory");
    }
  };

  const urlCopyHandle = () => {
    // console.log(itemDetails.shareUrl, shareUrl);
    setIsCopied(true);
    navigator.clipboard.writeText(
      itemDetails.shareUrl ? itemDetails.shareUrl : shareUrl
    );
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ download asset ~~~~~~~~~~~~~~~~~~~~~~~

  const downladAsset = () => {
    setLoading(true);
    axios
      .get(
        `${API.downloadAssets}?userId=${userId}&initiateId=${itemDetails.initiateId}`
      )
      .then((res) => {
        setLoading(false);
        let a = document.createElement("a");
        const { path } = res.data.data;
        a.href = path;
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  download model ~~~~~~~~~~~~~~~~~~~~~~~~~~
  const deleteModel = () => {
    const options = {
      initiateId: itemDetails.initiateId,
    };

    axios
      .delete(`${API.deleteObjects}/${itemDetails.initiateId}`)
      .then((res) => {
        console.log("delete model response==>", res.data.message);

        // toast.success(res.data.message, {
        //   position: toast.POSITION.TOP_CENTER,
        //   toastId: "success1",
        // });
        successToast(res.data.message);

        setTimeout(() => {
          window.location.reload();
        }, 1200);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadModel = () => {
    console.log(props.itemDetails);
    itemDetails.file3dUrl.map((url, i) => {
      console.log(i, url);
      if (url.split(".").pop() === "zip") {
        let a = document.createElement("a");
        a.href = url;
        a.click();
      }
    });
  };


  const consentCheck = () =>{
    const options =  {
      initiateId:props.itemDetails.initiateId,
      consent:!isChecked
    }
    
    axios.post(`${API.consentCheck}`,options).then((res)=>{
      console.log('consentResponse',res.data);
      setIsChecked(!isChecked)
    }).catch((err)=>{
      console.log(err);
    })
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    // console.log("userId", userData.userId);
    if (userData.userId) {
      setUserId(userData.userId);
    }
  }, []);
  return (
    <>
      {loading ? (
        <div className="loading">
          <TailSpin height={80} width={80} color="#255FFF" />
        </div>
      ) : (
        <ErrorBoundary errorMsg="Unable to load 3D Model.">
        <div className="scanDetailsContainer">
          <div className="modelCont">
            <ObjLoader
              url={itemDetails.extract3dUrl}
              item={itemDetails}
              setShareUrl={setShareUrl}
              shareUrl={shareUrl}
            />
          </div>
          <div className="infoCont">
            <div className="infoSec">
              <div className="textSec">
                {itemDetails.priorityStatus && (
                  <div className="priorityIcon">
                    <h3>Priority Processed</h3>
                    <img src={PriorityIcon} />
                  </div>
                )}
                <h4>{props.itemDetails.title}</h4>
                <p className="">{props.itemDetails.time}</p>
                <p className="" style={{ fontWeight: "700" }}>
                  Uploaded: {(props.itemDetails.fileSize / 1048576).toFixed(3)}
                  mb
                </p>
                <p className="">{props.itemDetails.description}</p>
              </div>
              <div>
                <div className="consentCheckbox">
                  <img
                    src={isChecked ? Check : UnCheck}
                    onClick={() => consentCheck()}
                  />
                  <p>
                    I would like to showcase this scan in Scanamze reels and
                    Scanamze social media handles
                  </p>
                </div>
                <Tooltip title="Download Assets" arrow>
                  <div className="downloadAsset" onClick={downladAsset}>
                    <p>Download Assets</p>
                    <img src={Download} alt="download" />
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="actionSec">
              <Tooltip title="Download" arrow>
                <div className="action" onClick={downloadModel}>
                  <RiDownload2Line
                    aria-hidden="true"
                    // style={{ fontSize: "26px" }}
                    className="actionImage"
                  />
                  <span>Download</span>
                </div>
              </Tooltip>
              <Tooltip title="Share" arrow>
                <div className="action" onClick={handleShow}>
                  <FiShare2 aria-hidden="true" className="actionImage" />
                  <span>Share</span>
                </div>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <div className="action" onClick={deleteModel}>
                  <RiDeleteBin6Line
                    aria-hidden="true"
                    className="actionImage"
                  />
                  <span>Delete</span>
                </div>
              </Tooltip>
            </div>

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~  Modals ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~ Share  modal ~~~~~~~~~~~~~~~~~~ */}

            <SocialShareModal
              itemDetails={itemDetails}
              shareUrl={shareUrl}
              urlCopyHandle={urlCopyHandle}
              isCopied={isCopied}
              show={show}
              handleClose={handleClose}
              showDownloadModal={showDownloadModal}
            />

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~ Download modal ~~~~~~~~~~~~~~~~~~ */}
            <DownloadMeshModal
              downloadModal={downloadModal}
              setDownloadModal={setDownloadModal}
              downloadModel={downloadModel}
              showUploadModal={showUploadModal}
            />

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~ Upload modal ~~~~~~~~~~~~~~~~~~ */}
            <SketchfabUploadModal
              uploadModal={uploadModal}
              uploadStart={uploadStart}
              closeUploadModalClose={closeUploadModalClose}
              title={title}
              setTitle={setTitle}
              description={description}
              setDescription={setDescription}
              tagshandler={tagshandler}
              handleFileReader={handleFileReader}
              uploadModel={uploadModel}
              progress={progress}
            />
          </div>
        </div>
      </ErrorBoundary>
      )}
    </>
  );
};
export default ScanItemDetails;
