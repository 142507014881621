import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home/Home";
import Login from "./Login/Login";
import Signup from "./Signup/Signup";
import Error from "./Error/Error";
import ImageUpload from "../FileUpload/ImageUpload";
import ScanList from "./ScanList/ScanList";
import PrivateRoute from "./PrivateRoute";
import LocalStorage from "../../service/LocalStorage";
import ResetPassword from "./ForgotPassword/ResetPassword";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Dashboard from "./Dashboard/Dashboard";
import Faqs from "../Pages/Category/Faqs";
import PrivacyPolicy from "../Pages/Category/PrivacyPolicy";
import TermsAndConditions from "../Pages/Category/TermsAndConditions";
import Profile from "./Profile/Profile";
import CookieConsent from "react-cookie-consent";
import AboutUs from "./Category/AboutUs";

const Index = () => {


  const localStorage = new LocalStorage();

  

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path={"/login"} component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/forgotpass" component={ForgotPassword} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/reset-password" component={ResetPassword} />

        {/* ~~~~~~~~~~~~~~~~~~~~~~ Private protected Routes ~~~~~~~~~~~~~~ */}
        <PrivateRoute exact path="/image_upload">
          <ImageUpload />
        </PrivateRoute>
        <PrivateRoute exact path="/scan_list">
          <ScanList />
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <Route component={Error} />
      </Switch>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Accept cookies !!"
        cookieName="scanamazeCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ fontSize: "14px",color:"white", backgroundColor:"#255FFF", border:"1px solid white" }}
        expires={7}
        enableDeclineButton
        declineButtonText="Reject cookies"
        // setDeclineCookie={false}
        declineButtonStyle={{ fontSize: "14px",color:"#255FFF", backgroundColor:"white", border:"1px solid #255FFF" }}
      >
        This Website uses  Third Party cookies access for better user experience
      </CookieConsent>
      
    </BrowserRouter>
  );
};

export default Index;
