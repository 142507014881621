import React from 'react'
import "./error.css"
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const Error = () => {

  const history = useHistory()

  return (
    <div className="notfound">
      <div class="text-wrapper">
        <div class="errorTitle" data-content="404">
          404
        </div>
        <div class="subtitle">
          Oops, the page you're looking for doesn't exist.
        </div>
        <br />
        <br />
        <Tooltip title="Go to homepage" arrow>
        <button  className="buttonMain" onClick={() => history.push("/")}>
          Go to homepage
        </button>
        </Tooltip>
      </div>
    </div>
  )
}

export default Error