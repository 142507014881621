import React from "react";
import "./footer.css";
import Fb from "../../../assets/image/icons/fb.png";
// import Fb from "../../../assets/image/icons/fb.svg";
import Twitter from "../../../assets/image/icons/twitter.png";
// import Twitter from "../../../assets/image/icons/twitter-icon.svg";
import Powered from "../../../assets/image/icons/powered.png";
import PlayStore from "./../../../assets/image/icons/PlayStore.png";
import AppStore from "./../../../assets/image/icons/AppStore.png";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";

import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  const comingSoon = () => {
    toast.info("Coming Soon!!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "success1",
    });
  };

  return (
    <>
      <div className="footerCont">
        <div className="linkSec">
          <a
            href="https://play.google.com/store/apps/details?id=com.tycho.scanamaze"
            target="_blank"
          >
            <img
              src={PlayStore}
              alt="playstore"
              className="footerStoreLink"
              style={{ width: "auto", height: "36px" }}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/scanamaze/id1613520722"
            target="_blank"
          >
            <img
              src={AppStore}
              alt="appstore"
              className="footerStoreLink"
              style={{ width: "auto", height: "36px", marginLeft: "13px" }}
            />
          </a>
        </div>
        <div className="copyRightCont">
          <h4>© Copyright 2022, Scanamaze</h4>
        </div>
        {/* <div className="poweredCont">
        <p>Powered By</p>
        <img src={Powered} alt="powered"/>
      </div> */}
        <div className="routeCont">
          <div className="d-flex align-items-center">
            <h4 onClick={() => history.push("/terms-and-conditions")}>
              Terms & Conditions
            </h4>
            <h4 onClick={() => history.push("/privacy-policy")}>
              Privacy Policy
            </h4>
            <h4 onClick={() => history.push("/about-us")}>About us</h4>
            <h4 onClick={() => history.push("/faqs")}>Faq's</h4>
          </div>
          <div className="d-flex align-items-center">
            <h4>Follow Us:</h4>
            <div className="d-flex align-items-center justify-content-between w-50">
              <a
                href="https://twitter.com/scanamaze"
                target="_blank"
                style={{ width: "19%" }}
              >
                <Tooltip title="Twitter" arrow>
                  <img src={Twitter} alt="twitter" />
                </Tooltip>
              </a>
              <a
                href="https://www.facebook.com/Scanamaze-110027438302999/"
                target="_blank"
                style={{ width: "19%" }}
              >
                <Tooltip title="Facebook" arrow>
                  <img src={Fb} alt="fb" style={{width:28, height:28}}/>
                </Tooltip>
              </a>
              <Tooltip title="Linkedin" arrow>
                <a
                  style={{
                    // top: "2px",
                    // position: "relative",
                    borderRadius: "50%",
                    padding: "0px 6px",
                    backgroundColor: "#255FFF",
                    color: "rgb(43, 54, 53)",
                    marginLeft: "7px",
                    // left: " 5px",
                  }}
                  href="https://www.linkedin.com/company/scanamaze/"
                  target="_blank"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </Tooltip>
              <Tooltip title="Instagram" arrow>
                <a
                  style={{
                    // top: "2px",
                    // position: "relative",
                    borderRadius: "50%",
                    padding: "0px 5px",
                    backgroundColor: "#255FFF",
                    color: "rgb(43, 54, 53)",
                    // left: " 17px",
                  }}
                  href="https://www.instagram.com/scanamaze3d/"
                  target="_blank"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
