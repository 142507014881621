import React, { useEffect } from "react";
import Index from "./components/Pages/Index";
import { ToastContainer } from "react-toastify";
import firebase from "./firebaseF";
import { useDispatch, useSelector } from "react-redux";
import { dBrowser } from "./components/Browser/DetectBrowser";

const App = () => {

  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const messaging = getMsg();

  function getMsg() {
    if (dBrowser() !== "Safari") {
      return firebase.messaging();
    } else {
      return null;
    }
  }

  console.log("browser -->", dBrowser());

  if (dBrowser() !== "Safari") {
    messaging
      .getToken(messaging, {
        vapidKey: "key=AAAA09tiM58:APA91bFzVScwO5fElOPQUKMsbA1KIG5oifpMF1jCmAyBrhAbSmbuk8FkbAuLlT5E-4IytsCd3mBJbN5P-c2PISDHhFpIHaUr1odegebohiewNpjeCx1nrSCPG-OyygPBUHPa4_9QoGfK",
      })
      .then((currentToken) => {
        // console.log("currentToken===>", currentToken);
        dispatch({ type: "DeviceToken", payload: currentToken });
        // Cookies.set("fcm_token", currentToken);
      })
      .catch((err) => {
        console.log("error fcm =====>", err);
      });
  }

  useEffect(() => {
    // console.log("token in App - - ->", state?.DeviceTokenData);
  }, [state.DeviceTokenData]);

  useEffect(() => {
    if (dBrowser() !== "Safari") {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("./firebase-messaging-sw.js")
          .then(function (registration) {
            console.log("Registration successful, scope is:", registration.scope);
            console.log("Registration successful, registration:", firebase.messaging.onMessageListener);
          })
          .catch(function (err) {
            console.log("Service worker registration failed, error:", err);
          });
        // navigator.serviceWorker.addEventListener('message',(event)=>console.log('event - - -recideiv ->',event))
      }
    }
  }, []);

  const onMessageListener = () =>
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
  if (dBrowser() !== "Safari") {
    onMessageListener()
      .then((payload) => {
        // console.log("live notification payload - - - - ", payload.notification);
      })
      .catch((err) => console.log("onMessageListener failed: ", err));

    const receiver = new BroadcastChannel("sw-messages");
    receiver.addEventListener("message", function eventListener(event) {
      // console.log("bc - event  - - -->", event);
    });
  }

  return (
    <div>
      <ToastContainer autoClose={1000} draggableDirection="y" />
      <Index />
    </div>
  )
}

export default App