import React, { useState, useEffect } from "react";
import LocalStorage from "../../../service/LocalStorage";
import { useHistory } from "react-router-dom";
import "./login.css";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import SocialLogin from "./SocialLogin";
import API from "../../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import {successToast,errorToast,infoToast,warnToast} from "../../Toast/Toast"
import { useSelector } from "react-redux";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const localStorage = new LocalStorage();
  const state = useSelector((state) => state);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // console.log("token", localStorage.getItem("token"));
      history.push("/dashboard");
    }
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.replace(/\s/g, "").length < 6) {
      errors.password = "Minimum six characters password required";
    }
    // else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(values.password)) {
    //   errors.password = 'Minimum eight characters, at least one letter and one number';
    // }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      // console.log(values);
      if (values.email.length > 0 && values.password.length > 0) {
        setLoading(true);
        const options = {
          email: values.email,
          password: values.password,
          deviceToken : state.DeviceTokenData
        };

        axios
          .post(`${API.login}`, options)
          .then((res) => {
            if (res.data.code === 1) {
              setLoading(false);
              
              errorToast(res.data.message)
            } else {
              
              localStorage.setItem("token", res.data.data.token);
              profileFetch(res.data.data.token, res.data.data.userId);

              setLoading(false);
              
              successToast(res.data.message)
             
            }
          })
          .catch((err) => {
            console.log("login error ===>", err);
          });
      } else {
        
        errorToast("Email and password required!!")
      }
    },
  });

  const profileFetch = (token, id) => {
    const headers = {
      "x-access-token": token,
    };
    axios
      .get(`${API.profileDetails}?userId=${id}`, { headers })
      .then((res) => {
        
        localStorage.setItem("userInfo", JSON.stringify(res.data.data));
        setTimeout(() => {
          // history.push("/dashboard");
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log("Profile error==>", err);
      });
  };

  return (
    <div className="mainContainer" onSubmit={formik.handleSubmit}>
      {loading ? (
        <div className="loading">
          <TailSpin  height={80} width={80} color="#255FFF"/>
        </div>
      ) : (
        <>
          {" "}
          <div>
            <ToastContainer autoClose={1000} draggableDirection="y" />
          </div>
          <div className="formContainer">
            <form className="form">
              <h2>Sign In</h2>
              <label>Email Address</label>
              <input
                type="email"
                required
                name="email"
                className="input"
                onChange={formik.handleChange}
                value={formik.values.email}

              />
              <p className="validationError">
                {formik.errors.email && formik.touched.email ? <div>{formik.errors.email}</div> : null}
           
              </p>
              <label>Password</label>
              <input
                type="password"
                required
                name="password"
                label="Password"
                className="input"
                onChange={formik.handleChange}
                value={formik.values.password}

              />

              <p className="validationError">

              {/* {formik.errors.password && formik.touched.password ? (
 -            <div>{formik.errors.password}</div>
 -          ) : null} */}

                {formik.errors.password && formik.touched.password ? <div>{formik.errors.password}</div> : null}
              </p>
              <p
                className="forgotpass"
                onClick={() => history.push("forgotpass")}
              >
                <Tooltip title="Forgot Password" arrow>
                  <span>Forgot Password?</span>
                </Tooltip>
              </p>

              <Tooltip title="Sign In" arrow>
                <button
                  className="buttonMain"
                  type="submit"
                  style={{ marginTop: "15px" }}
                  onClick={formik.handleSubmit}
                >
                  SIGN IN
                </button>
              </Tooltip>
            </form>

            <div>
              <p style={{ marginTop: "12px" }}>
                {" "}
                Don't have an account ?{" "}
                <Tooltip title="Sign Up" arrow>
                  <span
                    onClick={() => history.push("/signup")}
                    className="span"
                  >
                    Sign Up
                  </span>
                </Tooltip>
              </p>
            </div>

            <SocialLogin loading={loading} setLoading={setLoading}/>
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
