import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";

class LocalStorage{
    setItem = (key, value) => {
        localStorage.setItem(key, value);
    }

    getItem = (key) => {
        let item = localStorage.getItem(key);
        return item;
    }
    removeItem = (key) =>{
        localStorage.removeItem(key)
    }

    logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('access_token')
        // Cookies.remove("scanamazeCookie")
        // resetCookieConsentValue()
        return true;
    }

    loginStatus = () => {
        if(this.getItem('token') !== null && this.getItem('token') !== undefined && this.getItem('token') !== "" )
            return true;
        else
            return false;
    }
}

export default LocalStorage;
