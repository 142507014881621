class API{
    static base_url = 'https://api.scanamaze.com:8081/api/v1';
    // static base_url = 'https://stage-api.scanamaze.com:8082/api/v1';

    static login = `${this.base_url}/login`
    static signUp = `${this.base_url}/register`
    static forgotPassword = `${this.base_url}/forgot-password`
    static resetPassword = `${this.base_url}/reset-password`
    static socialLogin = `${this.base_url}/social-login`
    static updateSocial = `${this.base_url}/update-social`


    static uploadAssets = `${this.base_url}/uploadAssets`
    static completeTask = `${this.base_url}/completeTask`


    static aboutUs = `${this.base_url}/informationPage?pageType=about_us&from=web`
    static faqs = `${this.base_url}/getPagesData?pageType=faqs`
    static privacyPolicy = `${this.base_url}/getPagesData?pageType=privacyPolicy`
    static terms = `${this.base_url}/getPagesData?pageType=terms`
    static emailSupport = `${this.base_url}/emailsupportWeb`
    static tutorial = `${this.base_url}/tutorial`
    
    
    static updateProfile = `${this.base_url}/update-profile`


    static fileUpload = `${this.base_url}/file-upload`
    static profileDetails = `${this.base_url}/profile-details`
    static downloadAssets = `${this.base_url}/downloadAssets`
    static deleteObjects = `${this.base_url}/deleteObjects`
    static scanList = `${this.base_url}/scanList`

    // static shareFile = `${this.base_url}/shareFileToUpload`
    static shareFile = `${this.base_url}/upload-share-file`

    static deleteFile = `${this.base_url}/delete-share-file`
    static uploadThumbnail = `${this.base_url}/upload-thumbnail`
    static consentCheck = `${this.base_url}/consent`
    
}
export default API;
