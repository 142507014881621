import React, { useState } from "react";
import "./emailSupport.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import LocalStorage from "../../../service/LocalStorage";
import API from "../../../service/api_endpoint";
import { successToast, errorToast, infoToast } from "../../Toast/Toast";
import { TailSpin } from "react-loader-spinner";

function EmailSupport() {
  const localStorage = new LocalStorage();

  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = "Name is required";
    } else if (values.fullName.replace(/\s/g, "").length <= 2) {
      errors.fullName = "Name must be 3 character long.";
    } else if (values.fullName.replace(/\s/g, "").length > 15) {
      errors.fullName = "Name not more than 15 character.";
    } else if (!/^[a-zA-Z\s\.]*$/i.test(values.fullName)) {
      errors.fullName = "Name must contain alphabets.";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.description) {
      errors.description = "Description is required";
    } else if (values.description.replace(/\s/g, "").length < 5) {
      errors.description = "Description must be 5 character long.";
    } else if (values.description.replace(/\s/g, "").length > 200) {
      errors.description = "Description not more than 200 character.";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      fullName: `${userData.first_name + " " + userData.last_name}`,
      email: `${userData.email}`,
      description: "",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true)
      // console.log(values);

      // if (values.fullName.length > 0 && values.email.length > 0 && values.description.length > 0) {

      // const supportData = {
      //   full_name: values.fullName,
      //   email: values.email,
      //   description: values.description,
      // };
      // const form = new FormData()
      // form.append("full_name",values.fullName)
      // form.append("email",values.email)
      // form.append("description",values.description)
      const options = {
        full_name: values.fullName,
        email: values.email,
        description: values.description,
        userId:userData.userId
      };

      axios
        .post(`${API.emailSupport}`, options)
        .then((res) => {
          setLoading(false)
          successToast(res.data.message);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })

        .catch((err) => {
          console.log("Profile error==>", err);
        });

      // }
      // else {
      //     alert("All Fields are required!!");
      //   }
    },
  });

  return (
    <>
      {loading ? (
        <div className="loading">
          <TailSpin height={80} width={80} color="#255FFF" />
        </div>
      ) : (
        <div className="ScanContainers" onSubmit={formik.handleSubmit}>
          .<h4>Email Support</h4>
          <div className="detailsContainers">
            <div className="uploadCounts">
              <div className="emailMain">
                <div className="same">
                  <p className="font_weight">Full Name </p>
                  <input
                    type="text"
                    className="fnameInput"
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                    // onBlur={formik.handleBlur}
                    name="fullName"

                    // onChange={(e) => setFullName(e.target.value)}
                  />
                  <p className="validationError">
                    {formik.errors.fullName && formik.touched.fullName ? (
                      <div>{formik.errors.fullName}</div>
                    ) : null}
                  </p>
                </div>

                <div className="same">
                  <p className="font_weight">Email Address </p>
                  <input
                    type="email"
                    className="fnameInput"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    // onBlur={formik.handleBlur}
                    name="email"

                    // onChange={(e) => setEmail(e.target.value)}
                  />

                  <p className="validationError">
                    {formik.errors.email && formik.touched.email ? (
                      <div>{formik.errors.email}</div>
                    ) : null}
                  </p>
                </div>

                <div className="same">
                  <p className="font_weight">Description </p>
                  <textarea
                    rows="6"
                    className="disText"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    // onBlur={formik.handleBlur}
                    name="description"

                    // onChange={(e) => setDescription(e.target.value)}
                  />

                  <p className="validationError">
                    {formik.errors.description && formik.touched.description ? (
                      <div>{formik.errors.description}</div>
                    ) : null}
                  </p>
                </div>
              </div>

              <Tooltip title="Send" arrow>
                <button
                  className="buttonMain"
                  style={{ width: "100%", marginTop: "20px" }}
                  onClick={formik.handleSubmit}
                >
                  SEND
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EmailSupport;
