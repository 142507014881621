import React from 'react'
import { Modal, ProgressBar } from "react-bootstrap";

const VideoUploadModal = ({previewModal, isUploadStart, videoUrl, discardVideo, setPreviewModal, progress}) => {
  return (
    <Modal
          className="mainModal"
          show={previewModal}
          // onHide={()}
        >
          <Modal.Header>
            <Modal.Title className="modalTitle">Upload Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="videoDiv" style={{ marginTop: "0px" }}>
              {!isUploadStart && (
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <img src={videoUrl} />
                  <p style={{ margin: " 18px 9px" }}>
                    Please confirm that this is the video you would like to
                    share. You cannot edit or generate a new video for this 3D
                    model after accepting.
                  </p>
                </div>
              )}
              <div
                className="d-flex  justify-content-between"
                style={{ width: "40%" }}
              >
                {!isUploadStart && (
                  <>
                    {" "}
                    <button
                      className="buttonMain"
                      onClick={() => discardVideo()}
                    >
                      Discard
                    </button>
                    <button
                      className="buttonMain"
                      onClick={() => setPreviewModal(false)}
                    >
                      Accept
                    </button>
                  </>
                )}
              </div>
              {isUploadStart && (
                <div style={{ padding: "10px 5px" }}>
                  <ProgressBar animated now={progress} />
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
  )
}

export default VideoUploadModal