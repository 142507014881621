export const dBrowser = () => {
    const test = function (regexp) {
      return regexp.test(window.navigator.userAgent);
    };
    switch (true) {
      case test(/edg/i):
        return "Edge";
      case test(/trident/i):
        return "MIE";
      case test(/firefox|fxios/i):
        return "Firefox";
      case test(/opr\//i):
        return "Opera";
      case test(/ucbrowser/i):
        return "UC";
      case test(/samsungbrowser/i):
        return "Samsung";
      case test(/chrome|chromium|crios/i):
        return "Chrome";
      case test(/safari/i):
        return "Safari";
      default:
        return "Other";
    }
  };