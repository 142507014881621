import axios from "axios";
import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";

import { GoogleLogin, useGoogleLogout } from "react-google-login";
import { useHistory, useLocation } from "react-router-dom";
import LocalStorage from "../../../service/LocalStorage";
import AppleSignin from "react-apple-signin-auth";
import API from "../../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import { Modal } from "react-bootstrap";
import { successToast, errorToast, infoToast } from "../../Toast/Toast";
import { useSelector } from "react-redux";

// refresh token

const clientId =
  "402716096930-udkdnlvbfan89qkgif3g3tf2hk2j43l9.apps.googleusercontent.com";
// "1054316889950-7fv50a07pri1g5jjum39cecahut7k2k6.apps.googleusercontent.com"

const google_icons = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="20px"
    height="20px"
  >
    <path
      fill="#FFC107"
      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
    />
    <path
      fill="#FF3D00"
      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
    />
    <path
      fill="#4CAF50"
      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
    />
    <path
      fill="#1976D2"
      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
    />
  </svg>
);

const SocialLogin = ({loading,setLoading}) => {
  const state = useSelector((state) => state);
  const localStorage = new LocalStorage();
  const history = useHistory();
  const location = useLocation();
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [emailAdd, setEmailAdd] = useState("");

  const profileFetch = (token, id) => {
    const headers = {
      "x-access-token": token,
    };
    axios
      .get(`${API.profileDetails}?userId=${id}`, { headers })
      .then((res) => {
        setLoading(false)
        localStorage.setItem("userInfo", JSON.stringify(res.data.data));

        successToast("Successfully Logged in");
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1700);
      })
      .catch((err) => {
        console.log("Profile error==>", err);
      });
  };

  const appleCallbackHandle = (res) => {
    setLoading(true)
    console.log("Apple success", res);
    console.log("appleAut", res.authorization);
    // const { authorization, user } = res;
    // console.log("appleAuth", res.user, res.user.name.firstName, res.user.name.lastName);
    // const { id_token, code } = authorization;
    // const { email, name } = user;
    // const { firstName, lastName } = user.name;

    axios
      .post(`${API.socialLogin}`, {
        userName: res.user
          ? res.user.name.firstName + " " + res.user.name.lastName
          : "",
        userEmail: res.user ? res.user.email : "",
        login_type: "social",
        socialId: res.authorization.code,
        provider: "apple",
        social_token: res.authorization.id_token,
        deviceToken: state?.DeviceTokenData || '',
      })
      .then((res) => {
        if (res.data.code === 1) {
          setLoading(false)
          errorToast(res.data.message);
        } else {
          console.log("AppleLogin===>", res);
          localStorage.setItem("token", res.data.data.token);
          profileFetch(res.data.data.token, res.data.data.userId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const googleSuccess = (res) => {
    setLoading(true)
    console.log("Google success", res);
    if (res.status !== "unknown") {
      const { email, googleId, name } = res.profileObj;
      const { accessToken } = res;

      axios
        .post(`${API.socialLogin}`, {
          userName: name,
          userEmail: email,
          login_type: "social",
          socialId: googleId,
          provider: "google",
          social_token: accessToken,
          deviceToken: state?.DeviceTokenData || '',
        })
        .then((res) => {
          console.log("social", res.data);
          if (res.data.code === 1) {
            setLoading(false)
            errorToast(res.data.message);
          } else {
            localStorage.setItem("token", res.data.data.token);
            profileFetch(res.data.data.token, res.data.data.userId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const responseFacebook = (res) => {
    setLoading(true)
    if (res.status !== "unknown") {
      const { id, email, accessToken, name } = res;

      window.FB?.logout();
      console.log("facebook", email);

      axios
        .post(`${API.socialLogin}`, {
          userName: name,
          userEmail: email,
          login_type: "social",
          socialId: id,
          provider: "facebook",
          social_token: accessToken,
          deviceToken: state?.DeviceTokenData || '',
        })
        .then((res) => {
          console.log("facebook login response", res.data.data);
          setId(res.data.data.userId);
          if (res.data.code === 1) {
            setLoading(false)
            errorToast(res.data.message);
          }
          if (res.data.data.email.length > 0) {
            localStorage.setItem("token", res.data.data.token);
            profileFetch(res.data.data.token, res.data.data.userId);
          } else {
            setShow(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function facebookEmailHandler() {
    const options = {
      userId: id,
      email: emailAdd,
    };
    // console.log("option", options);
    axios
      .post(`${API.updateSocial}`, options)
      .then((res) => {
        if (res.data.code === 1) {
          setLoading(false)
          errorToast(res.data.message);
        } else {
          localStorage.setItem("token", res.data.data.token);
          profileFetch(res.data.data.token, res.data.data.userId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function submit() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAdd)) {
      setLoading(true)
      facebookEmailHandler();
    } else {
      errorToast("Invalid email");
    }
  }

  return (
    
      
        <>
          <div className="loginbtncont">
            <Tooltip title="Google" arrow>
              <span>
                {" "}
                <GoogleLogin
                  onSuccess={googleSuccess}
                  //   onFailure={onFailure}
                  clientId={clientId}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="googlebtn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {google_icons}
                      {/* <span style={{ marginLeft: "5px" }}>{props.for} with Google</span> */}
                      <span style={{ marginLeft: "5px" }}>Google</span>
                    </button>
                  )}
                  cookiePolicy={"single_host_origin"}
                  //   buttonText={props.for + " with Google"}
                  buttonText={"Google"}
                  autoLoad={false}
                  isSignedIn={false}
                />
              </span>
            </Tooltip>
            <Tooltip title="Facebook" arrow>
              <div>
                <FacebookLogin
                  appId="3119975251551137"
                  autoLoad={false}
                  fields="name,email,picture"
                  isSignedIn={false}
                  callback={responseFacebook}
                  cssClass="facebookbtn"
                  icon="fa-facebook-official fa-facebook-f"
                  authType="rerequest"
                  cookie={false}
                  scope="email"
                  isMobile={false}
                  disableMobileRedirect={true}
                  //   textButton={props.for + " with Facebook"}
                  textButton={"Facebook"}
                />
              </div>
            </Tooltip>

            <Tooltip title="Apple" arrow>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                <AppleSignin
                  authOptions={{
                    clientId: "com.tycho.scanamazeweb",
                    scope: "email name",
                    redirectURI: "https://www.scanamaze.com",
                    usePopup: true,
                    state: "",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  uiType="light"
                  onSuccess={appleCallbackHandle}
                  style={{
                    border: "1px solid #dfdfdf",
                    height: "44px",
                    borderRadius: "5px",
                  }}
                  className="appleSignin"
                />
              </div>
            </Tooltip>
          </div>
          <Modal className="mainModal" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="modalTitle">Enter Your Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                placeholder="Email"
                onChange={(e) => setEmailAdd(e.target.value)}
                className="input"
              />
              <button onClick={submit} className="buttonMain">
                Submit
              </button>
            </Modal.Body>
          </Modal>
        </>
      
    
  );
};
export default SocialLogin;
