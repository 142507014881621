import React, { useEffect, useState } from "react";
import "./category.css";
import GetStarted from "../Home/GetStarted";
import axios from "axios";
import API from "../../../service/api_endpoint";

function Faqs() {
  const [data, setData] = useState("");
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const FaqsFetch = () => {
    


    axios.get(`${API.faqs}`)
      .then((res) => {

        setData(res.data.data)

      })
      .catch((err) => {
        console.log("Profile error==>", err);
      })
  }

  useEffect(() => {

    FaqsFetch()
  }, []);


  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="PrivacyDiv">
        <div className="PrivacyText">Faq's</div>
      </div>
      <div className="page">
        <div className="pageText">

          {data && data.map((item , Index) => (
            <div>
              <ul>
                <li className="faqsText"> {item.question}  </li>
              </ul>
              <p className="answerFaqs">
                {item.answer}
              </p>
              <hr />
            </div>
          ))}


          {/* <div>
            <ul>
              <li className="faqsText"> Do we have a mobile app? </li>
            </ul>
            <p className="font">
              Yes! You can work from anywhere using your Android or iOS device
              by downloading our apps.
            </p>
            <hr />
          </div>

          <div>
            <ul>
              <li className="faqsText"> How can I scan? </li>
            </ul>
            <p className="font">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s,
            </p>
            <hr />
          </div>

          <div>
            <ul>
              <li className="faqsText"> How many formate we can export? </li>
            </ul>
            <p className="font">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s,
            </p>
            <hr />
          </div>

          <div>
            <ul>
              <li className="faqsText">
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </li>
            </ul>
            <p className="font">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
            <hr />
          </div>

          <div>
            <ul>
              <li className="faqsText">
                {" "}
                Is there a fee to use your platform?{" "}
              </li>
            </ul>
            <p className="font">
              Scanamaze is free to join and there is no fee to cash out or
              continue working.
            </p>
            <hr />
          </div>

          <div>
            <ul>
              <li className="faqsText">
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </li>
            </ul>
            <p className="font">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>
            <hr />
          </div> */}
        </div>
      </div>
      <GetStarted />
    </div>
  );
}

export default Faqs;
