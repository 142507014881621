import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "./tutorial.css";
import API from "../../../service/api_endpoint";

import { TailSpin } from "react-loader-spinner";

function Tutorial() {
  const [data, setData] = useState("");
  const [viewData, setViewData] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(true);

  const viewHandle = (data) => {
    setViewData(data);
    setToggle(true);
  };

  const TutorialFetch = () => {
    axios
      .get(`${API.tutorial}`)
      .then((res) => {
        setLoading(false)
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("Profile error==>", err);
      });
  };

  const onPlay = (item) => {
    data.map((vid, i) => {
      if (vid.access_by === "W") {
        const element = document.getElementById(`video${i}`);
        if (`video${i}` !== item) {
          element.pause();
        }
      }
    });
  };

  useEffect(() => {
    TutorialFetch();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading">
        <TailSpin  height={80} width={80} color="#255FFF" />
        </div>
      ) : (
        <div className="ScanContainerss">
          <h4 onClick={() => setToggle(false)}>Tutorials</h4>
          <div className="tutorialContainer">
            {!toggle ? (
              <div className="uploadCountss">
                {data &&
                  data.map(
                    (item, index) =>
                      item.access_by === "W" && (
                        <div className="mrT" key={index}>
                          <Card sx={{ maxWidth: 400 }}>
                            <CardMedia
                              id={`video${index}`}
                              component="video"
                              // autoPlay
                              controls
                              src={item.videoUrl}
                              style={{ height: "230px" }}
                              onPlay={() => onPlay(`video${index}`)}
                            />

                            <CardContent style={{ height: "159px" }}>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {item.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {item.description.slice(0, 200)}{" "}
                                {item.description && (
                                  <span
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#C6D5FF",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => viewHandle(item)}
                                  >
                                    view all
                                  </span>
                                )}
                              </Typography>
                            </CardContent>
                          </Card>
                        </div>
                      )
                  )}
              </div>
            ) : (
              <div className="tutDetails">
                <video controls>
                  {" "}
                  <source src={viewData.videoUrl} type="video/mp4" />{" "}
                </video>
                <div className="tutInfo">
                  <h2>{viewData.title}</h2>
                  <p>{viewData.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Tutorial;
