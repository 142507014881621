import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const SketchfabUploadModal = ({
  uploadModal,
  uploadStart,
  closeUploadModalClose,
  title,
  setTitle,
  description,
  setDescription,
  tagshandler,
  handleFileReader,
  uploadModel,
  progress,
}) => {
  return (
    <Modal
      className="mainModal"
      show={uploadModal}
      onHide={!uploadStart && closeUploadModalClose}
      centered
    >
      <ToastContainer autoClose={1000} draggableDirection="y" />
      <Modal.Header closeButton={uploadStart ? false : true}>
        <Modal.Title className="modalTitle">Upload Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="videoDiv" style={{ marginTop: "0px" }}>
          {uploadStart && <h5 className="Mesh">Uploading</h5>}
          {!uploadStart ? (
            <>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <div className="labelWrap">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="title"
                    className="input"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="labelWrap">
                  <label>Description</label>
                  <textarea
                    type="text"
                    placeholder="description"
                    className="input"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
                {/* <div className="labelWrap">
                          <label>Category</label>
                         
                          <Categories setCategories={setCategories} categories={categories} catData={catData}/>
                        </div> */}
                <div className="labelWrap">
                  <label>Tags</label>
                  <input
                    type="tags"
                    placeholder="tag1, tag2, tag3"
                    className="input"
                    // value={tags}
                    onChange={(e) =>
                      // setTags([
                      //   " Scanamaze",
                      //   ...e.target.value.split(","),
                      // ])
                      tagshandler(e)
                    }
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="labelWrap">
                  <label>Mesh</label>
                  <input
                    type="file"
                    className="input"
                    // value={file}
                    placeholder="filename"
                    onChange={(e) => handleFileReader(e)}
                    accept=".zip,.rar,.7zip"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <button className="buttonMain" onClick={() => uploadModel()}>
                Upload
              </button>
            </>
          ) : (
            <ProgressBar animated now={progress} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SketchfabUploadModal;
