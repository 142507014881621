import React from 'react'
import { Modal } from "react-bootstrap";

const ViewMoreModal = ({show, handleClose, viewMoreDetail}) => {
  return (
    <Modal className="mainModal" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="modalTitle">
              {viewMoreDetail.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="Mesh">
              <img
                src={viewMoreDetail.thumbnail_image}
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "1rem",
                }}
              />
              <p style={{ fontWeight: "bold" }}>
                {viewMoreDetail.time}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Uploaded: </span>{" "}
                {(viewMoreDetail.fileSize / 1048576).toFixed(1)}MB
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Status: </span>{" "}
                {viewMoreDetail.status}
              </p>
              <p style={{wordBreak: "break-all"}}>{viewMoreDetail.description}</p>
            </div>
          </Modal.Body>
        </Modal>
  )
}

export default ViewMoreModal