import React, { useEffect, useState, useRef } from "react";
import "./header.css";
import Logo from "./../../../assets/image/icons/LogoMain.png";
import PlayStore from "./../../../assets/image/icons/PlayStore.png";
import AppStore from "./../../../assets/image/icons/AppStore.png";
import Down from "./../../../assets/image/svg/down.svg";
import MenuProfile from "./../../../assets/image/svg/menuProfile.svg";
import Logout from "./../../../assets/image/svg/logout.svg";
import { useHistory } from "react-router-dom";
import LocalStorage from "../../../service/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";

function Header() {
  const localStorage = new LocalStorage();
  const [isLogin, setIsLogin] = useState(localStorage.getItem("token"));
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const history = useHistory();
  const [isDropdown, setIsDropdown] = useState(false);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log("dashboardStore", state.DashboardData);

  const comingSoon = () => {
    toast.info("Coming Soon!!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "success1",
    });
  };

  const logout = () => {
    localStorage.logout();
    history.push("/");
    window.location.reload();
  };

  const dropMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      dropMenu.current &&
      isDropdown &&
      !dropMenu.current.contains(e.target)
    ) {
      setIsDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  });

  const dashboardRoute = () => {
    history.push("/dashboard");
    dispatch({ type: "Dashboard", payload: "MyProfile" });
    setIsDropdown(!isDropdown);
  };

  useEffect(() => {
    return () => {
      setIsDropdown(false);
    };
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} draggableDirection="y" />
      <div className="header">
        <div className="logoSection">
          <img
            src={Logo}
            alt="logo"
            onClick={() => history.push("/")}
            className="logo"
          />
          <a
            href="https://play.google.com/store/apps/details?id=com.tycho.scanamaze"
            target="_blank"
          >
            <img
              src={PlayStore}
              alt="logo"
              className="playstoreLink"
              style={{ width: "auto", height: "36px" }}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/scanamaze/id1613520722"
            target="_blank"
          >
            <img
              src={AppStore}
              alt="logo"
              className="playstoreLink"
              style={{ width: "auto", height: "36px" }}
            />
          </a>
        </div>
        <div className="loginSection">
          {!isLogin ? (
            <>
              <Tooltip title="Sign Up" arrow>
                <p
                  className="signUpText"
                  onClick={() => history.push("/signup")}
                >
                  SIGN UP
                </p>
              </Tooltip>

              <Tooltip title="Log In" arrow>
                <button
                  className="buttonMain"
                  onClick={() => history.push("/login")}
                >
                  LOG IN
                </button>
              </Tooltip>
            </>
          ) : (
            <>
              <div className="dropdown">
                <div
                  className="userName dropdown"
                  id="dropdownMenuButton"
                  onClick={() => setIsDropdown(!isDropdown)}
                  onBlur={() => setIsDropdown(false)}
                >
                  <img
                    src={userData?.profileImg}
                    alt="proflePic"
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                    }}
                  />
                  <p>
                    {userData.first_name +
                      " " +
                      (userData.last_name ? userData.last_name : "")}
                  </p>
                  <img src={Down} alt="dropdown" className="downBtn" />
                </div>
                {isDropdown && (
                  <div
                    ref={dropMenu}
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{ display: "block", top: "34px", right: "0px" }}
                  >
                    <Tooltip title="Profile" arrow>
                      <a
                        className="dropdown-item menuProfile"
                        href="#"
                        onClick={() => dashboardRoute()}
                      >
                        <img src={MenuProfile} alt="menuProfile" />
                        <span>Profile</span>
                      </a>
                    </Tooltip>

                    <Tooltip title="Logout" arrow>
                      <a
                        className="dropdown-item menuProfile"
                        href="#"
                        onClick={logout}
                      >
                        <img
                          src={Logout}
                          alt="logout"
                          style={{ marginLeft: "4px" }}
                        />
                        <span>Logout</span>
                      </a>
                    </Tooltip>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
