import axios from "axios";
import React, { useEffect, useState } from "react";
import LocalStorage from "../../../service/LocalStorage";
import "./scanList.css";
import ScanItemDetails from "../../Pages/ScanList/ScanItemDetails";
import API from "../../../service/api_endpoint";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TailSpin } from "react-loader-spinner";
import ViewMoreModal from "../../Modals/ViewMoreModal";
import { successToast, errorToast, infoToast } from "../../Toast/Toast";
import PriorityIcon from "../../../assets/image/icons/ic_priority.png";

const ScanList = () => {
  // const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const localStorage = new LocalStorage();
  const [seeDetails, setSeeDetails] = useState(false);
  const [itemDetails, setItemDetails] = useState(null);
  const [limit, setLimit] = useState(40);
  const [selectId, setSelectId] = useState(0);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [viewMoreDetail, setViewMoreDetail] = useState(null);
  const [deleteRender, setDeleteRender] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
    setViewMoreDetail(item);
  };

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("loginToast")
    ) {
      // toast.success("Successfully Logged in to Sketchfab", {
      //   position: toast.POSITION.TOP_CENTER,
      //   toastId: "success1",
      // });
      successToast("Successfully Logged in to Sketchfab");
      localStorage.removeItem("loginToast");
    }
  }, []);

  const [data, setData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scanListFetch = (id) => {
    const headers = {
      "x-access-token": localStorage.getItem("token"),
    };
    axios
    .get(`${API.scanList}?pageNo=1&limit=${limit}&userId=${id}`,{headers })
      .then((res) => {
        console.log("ScanList ====>", res.data.data);
        setData([...res.data.data]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error ===>", err);
      });
  };

  const toggleSeeItemDetails = (data, i) => {
    setSeeDetails(true);
    setItemDetails(data);
    setLimit(40);
    setIndex(i);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    if (userData.userId) {
      // setUserId(userData.userId);
      scanListFetch(userData.userId);
    }
  }, [limit, seeDetails, deleteRender]);

  const optionHandler = (id) => {
    setSelectId(id);
    setOpen(!open);
  };

  const deleteModel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    axios
      .delete(`${API.deleteObjects}/${selectId}`)
      .then((res) => {
        successToast(res.data.message);
        // toast.success(res.data.message, {
        //   position: toast.POSITION.TOP_CENTER,
        //   toastId: "success1",
        // });
        // props.setSeeDetails(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1200);
        setDeleteRender(!deleteRender);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clickFuntion = (item, i) => {
    if (item.statusId === 0) {
      infoToast(
        "Your scan upload was interrupted or is being uploaded, please check again after some time."
      );
    }
    if (item.statusId === 2) {
      toggleSeeItemDetails(item, i);
    }
    if (item.statusId === 1) {
      toast.info(
        `Your scan is being processed. We will let you know when the 3D model is ready through your registered email and a notification.`,
        {
          // className: css({
          //   // background: "#00FF00 !important",
          //   color: "white !important",
          //   fontWeight: "bold",
          //   width: "400px",
          // }),
          autoClose: 2500,
          position: toast.POSITION.TOP_CENTER,
          toastId: "success1",
        }
      );
    } else {
      if (item.statusId === 3) errorToast(`${item.status}`);
    }
  };

  const statusDisplayed = (id) => {
    if (id === 0) {
      return "Uploading";
    } else if (id === 1) {
      return "Processing the Scan";
    } else if (id === 2) {
      return "Your scan has been processed successfully.";
    } else if (id === 3) {
      return "Your scan failed to process.";
    }
  };

  return (
    <>
      <div>
        {/* <ToastContainer autoClose={1000} draggableDirection="y" /> */}
      </div>
      <div className="allScanContainer">
        <h4>
          <span
            onClick={() => setSeeDetails(false)}
            style={{ fontSize: "14px" }}
          >
            Scans
          </span>{" "}
          {seeDetails && <span style={{ fontSize: "14px" }}>&gt; Details</span>}
        </h4>
        {!seeDetails ? (
          <>
            <>
              {!loading ? (
                <div className="itemContainer">
                  {data.length > 0 ? (
                    data.map((item, i) => {
                      if (item) {
                        return (
                          <div
                            key={item.initiateId}
                            style={{
                              width: "275px",
                              height: "310px",
                              // marginRight:"20px"
                            }}
                          >
                            <div
                              className="scanItemCard"
                              onClick={() => clickFuntion(item, i)}
                            >
                              {item.thumbnail_image.length > 0 ? (
                                <img src={item.thumbnail_image} />
                              ) : (
                                <img src="https://scanamaze.com:8080/profile/default-web.jpg" />
                              )}

                              <div className="scanItemInfo">
                                <h6>
                                  {item.title.length > 20
                                    ? item.title.slice(0, 30) + "..."
                                    : item.title}
                                </h6>
                                <p className="scanDate">{item.time}</p>
                                <p className="scanUpload">
                                  Uploaded:{" "}
                                  {item.fileSize / 1048576 < 1000
                                    ? (item.fileSize / 1048576).toFixed(1) +
                                      "MB"
                                    : (item.fileSize / 1048576000).toFixed(1) +
                                      "GB"}
                                </p>
                                <p className="scanUpload">
                                  Status: {statusDisplayed(item.statusId)}
                                </p>
                                <p className="scanInfo">
                                  {item.description.slice(0, 40)}
                                  {/* {item.description.length > 40 && "..."} */}
                                  {item.description.length > 40 && (
                                    <span
                                      onClick={(e) =>
                                        item.file3dUrl.length > 0
                                          ? toggleSeeItemDetails(item, i)
                                          : handleOpen(e, item)
                                      }
                                    >
                                      {" "}
                                      ...view all
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                            <Tooltip title="Options" arrow>
                              <span
                                style={{
                                  left: "244px",
                                  position: "relative",
                                  color: "white",
                                  top: "-314px",
                                  fontSize: "25px",
                                }}
                              >
                                <span
                                  className="dotSpan"
                                  onClick={() => optionHandler(item.initiateId)}
                                >
                                  {" "}
                                  <MoreVertIcon className="dots" />
                                </span>
                              </span>
                            </Tooltip>

                            {item.priorityStatus && (
                              <img
                                src={PriorityIcon}
                                style={{
                                  position: "relative",
                                  width: "22px",
                                  height: "22px",
                                  top: "-65%",
                                  left: "81%",
                                }}
                              />
                            )}

                            {selectId === item.initiateId && open && (
                              <div
                                // ref={dropMenu}
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                style={{
                                  left: "85px",
                                  backgroundColor: "white",
                                  height: "16%",
                                  display: "inline-block",
                                  bottom: "272px",
                                  position: "relative",
                                  width: "124px",
                                }}
                              >
                                <Tooltip title="Delete" arrow>
                                  <a
                                    className="dropdown-item menuProfile"
                                    href="#"
                                    // onClick={() => dashboardRoute()}
                                    style={{
                                      margin: "0px",
                                      color: "#4a4a4a",
                                      fontWeight: "700",
                                    }}
                                    onClick={(e) => deleteModel(e)}
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>
                                    <span
                                      style={{
                                        margin: "0px",
                                        paddingLeft: "12px",
                                      }}
                                    >
                                      Delete
                                    </span>
                                  </a>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>
                      <p>No Scans Found</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="loading">
                  <TailSpin height={80} width={80} color="#255FFF" />
                </div>
              )}
            </>
            {data.length > 20 && data.length === limit && (
              <div className="viewMore">
                <h4 onClick={() => setLimit((prev) => prev + 20)}>View More</h4>
              </div>
            )}
          </>
        ) : (
          <ScanItemDetails
            itemDetails={itemDetails}
            setSeeDetails={setSeeDetails}
            seeDetails={seeDetails}
            index={index}
          />
        )}
      </div>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  view More Modal ~~~~~~~~~~~~~~~~~~~~~~~~ */}

      {viewMoreDetail && (
        <ViewMoreModal
          show={show}
          handleClose={handleClose}
          viewMoreDetail={viewMoreDetail}
        />
      )}
    </>
  );
};

export default ScanList;
