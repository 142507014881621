import axios from "axios";
import React, { useState, useEffect } from "react";
import reactRouterDom from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TailSpin } from "react-loader-spinner";
import API from "../../../service/api_endpoint";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import {successToast,errorToast,infoToast,warnToast} from "../../Toast/Toast"


const ForgotPassword = () => {

  let history = useHistory();

  function handleClick() {
    history.push("/login");
  }

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (localStorage.getItem("token")) {
      // console.log("token", localStorage.getItem("token"));
      history.push("/dashboard");
    }
  }, []);


  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

  

    return errors;
};

const formik = useFormik({
    initialValues: {
        email: '',
    },
    validate,
    onSubmit: values => {
        // console.log(values)
        if (!values.email) {
          
          errorToast("Enter Your Email to proceed !!")
        } 
        else {
          setLoading(true);
    
          const options = {
            email: values.email,
            // linkUrl:"https://127.0.0.1:3000"
            linkUrl: "https://www.scanamaze.com",
          };
    
          axios
            .post(`${API.forgotPassword}`, options)
            .then((res) => {
              if (res.data.code === 1) {
               
                errorToast(res.data.message)
                setLoading(false);
              } else {
                
                successToast(res.data.message)
                setLoading(false);
                setEmail("");
                setTimeout(() => {
                  history.push("/login");
                }, 2000);
                
              }
    
              
            })
            .catch((err) => {
              console.log(err);
            });
        }

    },
});
  

 
  return (
    <div onSubmit={formik.handleSubmit}>
      <div>
        {/* <ToastContainer autoClose={1000} draggableDirection="y" /> */}
      </div>
      <div className="mainContainer">
        {loading ? (
          <div className="loading">
            <TailSpin  height={80} width={80} color="#255FFF"/>
          </div>
        ) : (
          <>
            <div className="formContainer" style={{ margin: "252px auto" }}>
              <form className="form">
                <div style={{ display: "flex", cursor: "pointer" }}>
                  <h2>
                    <ArrowBackIosIcon onClick={() => history.goBack()}/>
                    Forgot Password ?
                  </h2>
                </div>
                <label>Email Address</label>
                <input
                  type="email"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  // onBlur={formik.handleBlur}
                  placeholder="Enter your email"
                  className="input"
                  name="email"
              
                />
                <p className="validationError">
                {/* {formik.errors.email ? <div>{formik.errors.email}</div> : null} */}
              {formik.errors.email && formik.touched.email ? <div>{formik.errors.email}</div> : null}

              </p>
              <Tooltip title="Send" arrow>
              <button
                  className="buttonMain"
                  style={{ marginTop: "20px" }}
                  onClick={formik.handleSubmit}
                  // onClick={() => sendEmail()}
                >
                  SEND
                </button>
              </Tooltip>
                
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
